import instance from "@/utils/request";
import { getMiddlewareUrl } from "@/utils/function";

// 获取资源上传地址
export function getUploadUrl(value:any) {
    instance.defaults.baseURL = getMiddlewareUrl();
    return instance.get('/store/v1/sign-upload-object',{
        params:value
    });
}

// 上传图片
export function uploadResource(url:any,value:any) {
    instance.defaults.baseURL = getMiddlewareUrl();
    return instance.put(url,value);
}

// 获取国家
export function getCountry() {
    try {
        instance.defaults.baseURL = getMiddlewareUrl();
    } catch (error) {
        instance.defaults.baseURL = process.env.VUE_APP_GAME_AMIDDLE_BASEURL;
    }

    return instance.get('/cs/get_country');
}