export default {
    'notice.充值成功': 'Recarga concluída com sucesso',
    'notice.领取奖励成功': 'Resgatado com sucesso',
    'notice.您未参与才活动': 'Você não participou deste evento',
    'notice.已经领取过了': 'Já resgatado',
    'notice.提款申请成功，请等待': 'Solicitação de saque enviada com sucesso, aguarde',
    'notice.密码错误': 'Senha incorreta',
    'notice.验证码已发送': 'Código de verificação enviado',
    'notice.提款失败': 'Saque falhou',
    'notice.验证码有误或已过期': 'Código de verificação incorreto ou expirado',
    'notice.该手机号已使用，请更换': 'Este número de telefone já está em uso, por favor troque',
    'notice.您当前账号已绑定过手机号': 'Sua conta atual já está vinculada a um número de telefone',
    'notice.渠道维护请重新选择': 'Canal em manutenção, por favor escolha outro',
    'notice.此充值方式不能参与活动': 'Este método de recarga não pode ser usado no evento',
    'notice.小于此渠道最低充值金额': 'Abaixo do valor mínimo de recarga para este canal',
    'notice.中间件配置不存在禁止登录': 'Configuração de middleware ausente, login proibido',
    'notice.正在维护': 'O canal {channel} está em manutenção',
    'notice.领取新版注册送金币': 'Você resgatou com sucesso {amount} moedas de ouro',
    'notice.领取新版注册送金币最后一次领取': 'Você resgatou com sucesso {amount} moedas de ouro, todas as recompensas foram recebidas e o evento será encerrado',
    'notice.领取新版注册送金币最后一次领取2': 'Recebeu com sucesso {amount} Uma vez que todas as suas moedas de ouro com desconto de recarga foram recebidas com sucesso, aguarde pacientemente até que o próximo evento de desconto de recarga comece.',
    'notice.appKey不一致禁止登录': 'login proibido',
    'notice.账号在其它地方被登录': 'Sua conta foi acessada em outro local',
    'notice.非常抱歉，您的提款申请失败，可能是卡号错误或通道维护等原因。请找客服核对': 'Desculpe, sua solicitação de saque falhou, pode ser devido a um erro no número do cartão ou manutenção do canal. Entre em contato com o suporte.',
    'notice.提款金额不能大于': 'O valor do saque não pode ser superior a {amount}',
    'notice.游戏暂未开放': 'O jogo ainda não está disponível',
    'notice.账号被其它设备登录': 'sistema detectou que sua conta pode estar logada em vários dispositivos! Se você não estiver ciente disso, por favor, altere a senha da sua conta imediatamente!',
    'notice.该银行卡号绑定在其他账号中': 'O número do cartão bancário está ligado a outra conta',
    'notice.今日提款额超出上限': 'O valor de saque de hoje excede o limite',
    'notice.今日提款次数超出上限': 'O número de saques de hoje excede o limite',

    'notice.游戏VIP2才能玩您当前是VIP1': 'Este jogo só pode ser jogado por VIP2, atualmente você é VIP1. Recarregue R$100 para se tornar VIP2',
}
