export default {
  "mine.退出登录": "Đăng xuất",
  "mine.用户ID": "ID",
  "mine.XP到下一个等级": "Nâng cấp lên cấp độ tiếp theo",
  "mine.vip": "VIP",
  "mine.充值": "Nạp tiền",
  "mine.下注": "Đặt cược",

  "mine.menus.充值": "Nạp tiền",
  "mine.menus.提现": "Rút tiền",
  "mine.menus.账户明细": "Tài khoản",
  "mine.menus.代理": "Đại lý",
  "mine.menus.每日签到": "Điểm danh",
  "mine.menus.vip": "VIP",
  "mine.menus.下注记录": "Lịch sử cược",
  "mine.menus.邮件通知": "Thông báo",
  "mine.menus.注册送": "Ưu đãi tân thủ",
  "mine.menus.tip.活动未开启": "Hoạt động chưa bắt đầu",

  "mine.userdetail.用户ID": "ID",
  "mine.userdetail.总输赢": "Tổng thắng/thua",
  "mine.userdetail.总下注": "Tổng đặt cược",
  "mine.userdetail.仅计算当月数据": "Chỉ tính dữ liệu cho tháng hiện tại ",
  "mine.userdetail.最喜欢玩的3款游戏": "3 trò chơi yêu thích để chơi",
  "mine.userdetail.用户信息": "Thông tin người dùng ",
  "mine.userdetail.绑定手机": "Thêm số tiền thoại",
  "mine.userdetail.绑定邮箱": "Thêm email",
  "mine.userdetail.更换": "Thay đổi",
  "mine.userdetail.绑定": "Ràng buộc",

  "mine.replacename.用户名修改": "Sửa đổi tên người dùng",
  "mine.replacename.用户名": "Tên người dùng",
  "mine.replacename.请输入用户名": "Nhập tên người dùng",
  "mine.replacename.确认": "Xác nhận",
  "mine.replacename.tip.请输入用户名": "Nhập tên người dùng",

  "mine.replaceemail.绑定邮箱": "Ràng buộc email",
  "mine.replaceemail.邮箱": "Email",
  "mine.replaceemail.请输入邮箱": "Nhập địa chỉ email",
  "mine.replaceemail.确认": "Xác nhận",
  "mine.replaceemail.tip.请输入邮箱": "Nhập địa chỉ email",
  "mine.replaceemail.tip.请输入正确的邮箱地址": "Nhập địa chỉ email chính xác",

  "mine.betrecord.下注记录": "Lịch sử cá cược",
  "mine.betrecord.最多展示前100条下注详情": "Hiển thị tối đa 100 chi tiết cá cược đầu tiên",
  "mine.betrecord.暂无下注记录": "Chưa có lịch sử cá cược nào",
  "mine.betrecord.下注号": "Phiên",
  "mine.betrecord.时间": "Thời gian",
  "mine.betrecord.下注金额": "Số tiền cược",
  "mine.betrecord.输赢": "Thắng/Thua",

  "mine.email.邮件通知": "Thông báo",
  "mine.email.已领取": "Đã nhận",
  "mine.email.未领取": "Nhận",
  "mine.email.收起": "Thu hồi",
  "mine.email.展开": "Mở rộng",
  "mine.email.暂无邮件": "Chưa có email nào",
  "mine.email.type.VIP返利提取成功": "Hoàn trả VIP",
  "mine.email.value.VIP返利提取成功": "Đã nhận được thành công số tiền hoàn trả {amount}",
  "mine.email.type.幸运签到": "Điểm danh",
  "mine.email.value.幸运签到": "Tiền thưởng {amount} đã cộng vào tài khoản",
  "mine.email.type.NEWCOMER SPECIAL": "Đăng ký để tặng",
  "mine.email.value.NEWCOMER SPECIAL": "Cảm ơn bạn đã tham gia ưu đãi tân thủ! Phần thưởng {amount} đã được khóa! Tiền nạp đặt cược để mở khóa! sau khi mở khóa tiền vàng, rút tiền không cần cá cược! Để biết thêm các quy tắc, vui lòng kiểm tra chi tiết sự kiện!",
  "mine.email.type.提款成功": "Rút tiền thành công",
  "mine.email.value.提款成功": "Xin chúc mừng, bạn đã rút tiền thành công {amount}. Nếu tình trạng rút tiền thành công nhưng bạn chưa thực sự nhận được tiền, ngân hàng thường chuyển tiền trong vòng 24 giờ, hãy kiên nhẫn! Nếu bạn không nhận được tiền sau 24 giờ, hãy vào trò chơi và liên hệ với dịch vụ hỗ trợ!",
  "mine.email.type.提款失败": "Rút tiền thất bại",
  "mine.email.value.提款失败": "Xin lỗi, do thông tin thẻ ngân hàng sai hoặc kênh rút tiền đang bảo trì gây bạn rút tiền thất bại, hãy vào game liên hệ với CSKH để kiểm tra",
  "mine.email.type.提现扣除活动赠送的金币": "Rút tiền sẽ khấu trừ số tiền tặng",
  "mine.email.value.提现扣除活动赠送的金币": "Rút tiền sẽ khấu trừ số tiền tặng:{amount}",
  "mine.email.tip.领取成功": "Đã nhận thành công",
  "mine.email.type.Recharge Rebates": "Hoàn tiền Nạp tiền",
  "mine.email.value.Recharge Rebates": "Cảm ơn bạn đã tham gia Hoàn Trả Nạp Tiền! Phần thưởng {amount} đã được khóa! Tiền nạp đặt cược để mở khóa! sau khi mở khóa tiền vàng, rút tiền không cần cá cược! Để biết thêm các quy tắc, vui lòng kiểm tra chi tiết sự kiện!",
  "mine.email.type.Recharge Rebate Activity Reminder": "Sự kiện hoàn trả nạp tiền",
  "mine.email.value.Recharge Rebate Activity Reminder": "Kính chào quý khách!Cảm ơn quý khách đã tham gia trò chơi của chúng tôi và hoàn tất lần nạp tiền đầu tiên! Theo quy định của sự kiện, số dư thưởng trải nghiệm mà quý khách nhận được khi đăng ký đã được xóa bỏ. Cảm ơn các quý khách ủng hộ và thông cảm.Chúng tôi sẽ tiếp tục mang đến cho quý khách những trải nghiệm game phong phú, chúc quý khách giành được nhiều giải thưởng lớn trong trò chơi! Nếu có bất kỳ câu hỏi nào, xin vui lòng liên hệ với CSKH của chúng tôi, chúng tôi sẽ tận tâm phục vụ quý khách.",

  "mine.accountdetail.账户明细": "Chi tiết tài khoản",
  "mine.accountdetail.可提现": "Rút được",
  "mine.accountdetail.金额": "Số tiền",
  "mine.accountdetail.充值金币": "Tiền nạp",
  "mine.accountdetail.签到金币": "Tiền điểm danh",
  "mine.accountdetail.充值金详情": "Chi tiết tiền nạp",
  "mine.accountdetail.签到金详情": "Chi tiết tiền điểm danh",
  "mine.accountdetail.提现": "Rút tiền",
  "mine.accountdetail.流水": "Lượng cược",
  "mine.accountdetail.余额": "Số dư",
  "mine.accountdetail.无流水要求": "Không cần lượng cược",
  "mine.accountdetail.流水倍数": "Vòng cược * {rate}",
  "mine.accountdetail.加载中": "Đang tải",
  "mine.accountdetail.desc.13014": "Tiền xu này không thể rút tiền. Sau khi hoàn thành 0.1 vòng cá cược, bạn có thể tham gia sự kiện và rút tiền!",

  "mine.vip.VIP详情": "Chi tiết VIP",
  "mine.vip.成为VIP您可获得以下奖励": "Trở thành VIP để nhận thưởng như sau",
  "mine.vip.VIP奖金": "Thưởng VIP",
  "mine.vip.提现福利": "Lợi ích",
  "mine.vip.下注返利": "Hoàn trả",
  "mine.vip.XP到下一个等级": "Nâng cấp lên cấp độ tiếp theo",
  "mine.vip.充值": "Nạp tiền",
  "mine.vip.提现": "Rút tiền",
  "mine.vip.下注": "Đặt cược",
  "mine.vip.成为VIP可获取更多福利": "Trở thành VIP để nhận thêm nhiều lợi ích!",
  "mine.vip.完成以下任意任务即可成为VIP": "Hoàn thành bất kỳ nhiệm vụ nào sau đây để trở thành VIP",
  "mine.vip.充值xx以上": "Nạp tiền {amount} trở lên",
  "mine.vip.完成一次充值+一次提现": "Hoàn thành 1 lần nạp tiền + 1 lần rút tiền",
  "mine.vip.代理收入达到xx以上": "Thu nhập đại lý đạt {amount} trở lên",
  "mine.vip.维持当前VIP要求": "Điều kiện duy trì VIP hiện tại",
  "mine.vip.下注金额超过xx": " Số tiền đặt cược vượt quá {symbol}{amount}",
  "mine.vip.当前VIP享受以下福利": "VIP hiện tại được hưởng các quyền lợi sau:",
  "mine.vip.升级奖金": "Thưởng nâng cấp",
  "mine.vip.可获得xx": "Nhận được: {symbol}{amount}",
  "mine.vip.领取": "Nhận",
  "mine.vip.已领取": "Đã nhận",
  "mine.vip.本周充值返利": "Hoàn trả nạp tiền tuần này",
  "mine.vip.最小充值": "Nạp tiền tối thiểu",
  "mine.vip.去充值": "Đi nạp",
  "mine.vip.提现返利": "Lợi ích rút tiền",
  "mine.vip.每日最高可提现": "Rút tối đa/ngày",
  "mine.vip.最高可提现": "Rút Tiền Tối Đa",
  "mine.vip.提现进度": "Tiến độ rút tiền",
  "mine.vip.游戏下注返利": "Hoàn trả tiền cá cược game",
  "mine.vip.slot": "Slot",
  "mine.vip.棋牌": "Game bài",
  "mine.vip.视讯": "Live Casino",
  "mine.vip.体育": "Thể thao",

}