export default {
  'wallt.充值': 'เติมเงิน',
  'wallt.提现': 'ถอนเงิน',

  'wallt.recharge.充值方式': 'วิธีเติมเงิน',
  'wallt.recharge.充值金额': 'จำนวนเงินเติม',
  'wallt.recharge.确认充值': 'ยืนยันการเติมเงิน',
  'wallt.recharge.请输入银行账户': 'กรุณากรอกบัญชีธนาคาร',
  'wallt.recharge.请输入开户名': 'กรุณากรอกชื่อเจ้าของบัญชี',
  'wallt.recharge.请输入手机号': 'กรุณากรอกหมายเลขโทรศัพท์',
  'wallt.recharge.输入您的实际付款Momo帐户以完成付款': 'กรุณากรอกบัญชี Momo ที่คุณใช้จริงเพื่อทำรายการให้เสร็จสมบูรณ์',
  'wallt.recharge.请准确填写您的支付账户,填写错误可能会导致转账资金的损失': 'กรุณากรอกข้อมูลบัญชีการชำระเงินอย่างถูกต้อง การกรอกผิดอาจทำให้สูญเสียเงินโอน',
  'wallt.recharge.填写正确的银行相关信息可提高充值成功率': 'กรอกข้อมูลธนาคารให้ถูกต้องเพื่อเพิ่มโอกาสความสำเร็จในการเติมเงิน',
  'wallt.recharge.汇率': 'อัตราแลกเปลี่ยน',
  'wallt.recharge.参与活动': 'เข้าร่วมกิจกรรม',
  'wallt.recharge.注册送': 'โบนัสสมัคร',
  'wallt.recharge.签到': 'เช็คอิน',
  'wallt.recharge.当前参与活动': 'กิจกรรมปัจจุบันที่เข้าร่วม',
  'wallt.recharge.充值额度': 'จำนวนเงินเติม',
  'wallt.recharge.赠送额度': 'จำนวนโบนัส',
  'wallt.recharge.您还不能参加此活动': 'คุณยังไม่สามารถเข้าร่วมกิจกรรมนี้ได้',
  'wallt.recharge.下订单失败': 'คำสั่งซื้อไม่สำเร็จ',
  'wallt.recharge.下订单失败，请尝试大额支付或其他支付通道!': 'คำสั่งซื้อไม่สำเร็จ กรุณาลองชำระเงินจำนวนมากขึ้นหรือลองช่องทางการชำระเงินอื่น!',
  'wallt.recharge.当前充值渠道正在维护': 'ช่องทางการเติมเงินนี้กำลังบำรุงรักษา',
  'wallt.recharge.当前银行正在维护': '当前银行正在维护',
  'wallt.recharge.您当前不能参与活动确认要充值吗': 'คุณไม่สามารถเข้าร่วมกิจกรรมนี้ได้ แน่ใจหรือว่าต้องการเติมเงิน?',
  'wallt.recharge.选择银行卡': 'เลือกบัตรธนาคาร',
  'wallt.recharge.充值': 'เลือกจำนวนเงิน',
  'wallt.recharge.手续费': 'ค่าธรรมเนียมการทำธุรกรรม',
  'wallt.recharge.需要扣除手续费': 'วิธีการเติมเงินนี้ต้องเสียค่าธรรมเนียม',
  'wallt.recharge.实际到账': 'จำนวนเงินที่ได้รับจริง',
  'wallt.recharge.你不能参与任何活动,还需要扣除手续费': 'วิธีการเติมเงินนี้ไม่รองรับการเข้าร่วมกิจกรรมใด ๆ! หากมูลค่าของบัตรขูดสูงกว่าคำสั่งเติมเงินที่คุณสร้าง ผู้เล่นจะต้องรับผิดชอบต่อความสูญเสียส่วนต่าง!',
  'wallt.recharge.确认': 'ยืนยัน',
  'wallt.recharge.二维码描述': 'หากไม่สามารถใช้รหัส QR ได้ กรุณาคัดลอกรายละเอียดด้านล่างสำหรับการโอนเงิน',
  'wallt.recharge.银行名称': 'ชื่อธนาคาร',
  'wallt.recharge.账户': 'หมายเลขบัญชี',
  'wallt.recharge.账户名称': 'ชื่อบัญชี',
  'wallt.recharge.转账金额': 'จำนวนเงินที่โอน',
  'wallt.recharge.复制': 'คัดลอก',
  'wallt.recharge.注意': 'ข้อควรระวัง',
  'wallt.recharge.请充值相应金额的USDT!不正确的充值金额将导致游戏币无法充值!': 'กรุณาเติมเงินตามจำนวน USDT ที่ระบุ! การเติมเงินที่ไม่ถูกต้องจะทำให้ไม่สามารถเติมเหรียญเกมได้!',
  'wallt.recharge.您有一笔订单未支付': 'คุณมีคำสั่งซื้อที่ยังไม่ได้ชำระจำนวน {amount} USDT กรุณาชำระเงินก่อน!',
  'wallt.recharge.规则1': '1. กรุณาป้อนข้อมูลและจำนวนเงินตามที่ระบบกำหนดให้ถูกต้อง หากข้อมูลไม่ถูกต้อง จะไม่สามารถเพิ่มคะแนนอัตโนมัติได้',
  'wallt.recharge.规则2': '2. หากไม่สามารถใช้รหัส QR ได้ กรุณาคัดลอกบัญชีชำระเงิน',
  'wallt.recharge.规则3': '3. ห้ามรีเฟรชเบราว์เซอร์ในขณะที่ทำการโอน',
  'wallt.recharge.认证': 'การรับรอง PCI DSS',
  'wallt.recharge.认证描述': 'ได้รับการรับรองความปลอดภัยข้อมูลการเงินและเป็นไปตามมาตรฐานความปลอดภัยสากล',
  'wallt.rechargechannel.网关': 'เกตเวย์',
  'wallt.rechargechannel.银行转账': 'การโอนเงินผ่านธนาคาร',
  'wallt.rechargechannel.ZaloPay': 'ZaloPay',
  'wallt.rechargechannel.VIETQR': 'VIETQR',
  'wallt.rechargechannel.MomoPay': 'MomoPay',
  'wallt.rechargechannel.Nạp thẻ cào': 'Nạp thẻ cào',
  'wallt.rechargechannel.Chỉ định QR BANK': 'ธนาคารที่กำหนดสำหรับการสแกน QR',
  'wallt.rechargechannel.VTPay': 'VTPay',
  'wallt.rechargechannel.银行扫码': 'QR Code ธนาคาร',

  'wallt.withdraw.绑定银行卡': 'เชื่อมบัตรธนาคาร',
  'wallt.withdraw.更换银行卡': 'เปลี่ยนบัตรธนาคาร',
  'wallt.withdraw.提现信息': 'ข้อมูลการถอนเงิน',
  'wallt.withdraw.最高可提现金额': 'จำนวนเงินที่ถอนได้สูงสุด',
  'wallt.withdraw.当前选择金额': 'จำนวนเงินที่เลือกปัจจุบัน',
  'wallt.withdraw.服务费': 'ค่าบริการ',
  'wallt.withdraw.收到金额': 'จำนวนเงินที่ได้รับ',
  'wallt.withdraw.手续费': 'ค่าธรรมเนียมการทำธุรกรรม',
  'wallt.withdraw.当前提现金额大于最大可提现金额': 'จำนวนเงินที่ถอนปัจจุบันเกินจากจำนวนสูงสุดที่ถอนได้',
  'wallt.withdraw.提现金额': 'จำนวนเงินที่ถอน',
  'wallt.withdraw.当前提现渠道正在维护': 'ช่องทางการถอนปัจจุบันกำลังบำรุงรักษา',
  'wallt.withdraw.确定': 'ยืนยัน',
  'wallt.withdraw.当前金额正在维护': 'จำนวนเงินปัจจุบันกำลังบำรุงรักษา',
  'wallt.withdraw.当前提现金额不符合最大可提现金额': 'จำนวนเงินที่ถอนปัจจุบันไม่ตรงกับจำนวนสูงสุดที่ถอนได้',
  'wallt.withdraw.不满足VIP提款金额要求请升级VIP': 'ไม่เป็นไปตามข้อกำหนดจำนวนการถอน VIP! กรุณาอัพเกรดเป็นวีไอพี!',
  'wallt.withdraw.不满足vip1提现金额要求请充值': 'ไม่เป็นไปตามข้อกำหนดการถอน VIP ในปัจจุบัน! เติมเงิน R$150 เพื่อเป็น VIP2 และถอนเงินได้เต็มจำนวน!',
  'wallt.withdraw.您的余额已满足提现要求可以立即提现': 'ยอดคงเหลือของคุณถึงเกณฑ์ที่สามารถถอนได้แล้ว! เป็น VIP เพื่อถอนได้ทันที!',
  'wallt.withdraw.您的余额已满足提现要求可以立即提现3': 'เติมเงิน R$100 เพื่อเป็น VIP2! VIP2 สามารถถอนเงินได้เต็มจำนวนทันที!',
  'wallt.withdraw.您的余额已满足提现要求可以立即提现4': 'คุณสามารถถอนเงิน R$10 ได้ทันที! กรุณาถอนเงินทันที!',
  'wallt.withdraw.您的余额已满足提现要求可以立即提现5': 'เติมเงินอีก R$50 เพื่อถอนเงินได้เต็มจำนวน',
  'wallt.withdraw.成为VIP后可以立即提现': 'เมื่อเป็น VIP แล้ว สามารถถอนได้ทันที',
  'wallt.withdraw.请选择银行': 'กรุณาเลือกธนาคาร',
  'wallt.withdraw.请输入您的姓名': 'กรุณาใส่ชื่อของคุณ',
  'wallt.withdraw.请输入您的银行卡号': 'กรุณาใส่หมายเลขบัตรธนาคารของคุณ',
  'wallt.withdraw.请输入您的钱包地址': 'รุณากรอกที่อยู่กระเป๋าเงินของคุณ',
  'wallt.withdraw.请输入您的电子邮件': 'กรุณาใส่อีเมลของคุณ',
  'wallt.withdraw.请输入提款密码': 'กรุณาใส่รหัสถอนเงิน',
  'wallt.withdraw.请确认提款密码': 'กรุณายืนยันรหัสถอนเงิน',
  'wallt.withdraw.请检查表单是否填写完整': 'กรุณาตรวจสอบว่าแบบฟอร์มกรอกครบถ้วนหรือไม่',
  'wallt.withdraw.请输入正确的邮箱': 'กรุณาใส่อีเมลที่ถูกต้อง',
  'wallt.withdraw.密码不能少于6位': 'รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร',
  'wallt.withdraw.两次密码输入不一致': 'รหัสผ่านไม่ตรงกัน',
  'wallt.withdraw.提款密码': 'รหัสถอนเงิน',
  'wallt.withdraw.忘记密码': 'ลืมรหัสผ่าน',
  'wallt.withdraw.请输入密码': 'กรุณาใส่รหัสผ่าน',
  'wallt.withdraw.更换银行卡需要验证交易密码': 'การเปลี่ยนบัตรธนาคารต้องยืนยันรหัสการทำธุรกรรม',
  'wallt.withdraw.密码错误': 'รหัสผ่านไม่ถูกต้อง',
  'wallt.withdraw.服务器内部错误': 'ข้อผิดพลาดภายในเซิร์ฟเวอร์',
  'wallt.withdraw.短信验证': 'การยืนยันทาง SMS',
  'wallt.withdraw.手机号': 'หมายเลขโทรศัพท์',
  'wallt.withdraw.请输入验证码': 'กรุณาใส่รหัสยืนยัน',
  'wallt.withdraw.发送': 'ส่ง',
  'wallt.withdraw.验证码发送成功': 'ส่งรหัสยืนยันสำเร็จ',
  'wallt.withdraw.验证码错误': 'ข้อผิดพลาดของรหัสยืนยัน',
  'wallt.withdraw.发送验证码': 'ส่งรหัสยืนยัน',
  'wallt.withdraw.br.绑定提款账号': 'ผูกบัญชีการถอนเงิน',
  'wallt.withdraw.br.请选择取款方式': 'กรุณาเลือกวิธีการถอน',
  'wallt.withdraw.br.请输入用户姓名': 'กรุณากรอกชื่อผู้ใช้',
  'wallt.withdraw.br.请输入取款账号': 'กรุณากรอกหมายเลขบัญชีการถอน',
  'wallt.withdraw.br.请输入邮箱': 'กรุณากรอกอีเมล',
  'wallt.withdraw.br.请输入手机号': 'กรุณากรอกหมายเลขโทรศัพท์',
  'wallt.withdraw.br.请输入正确的CPF': 'กรุณากรอก CPF ที่ถูกต้อง',

  'wallt.withdraw.可提现佣金': 'คอมมิชชั่นที่สามารถถอนเงินได้',
  'wallt.withdraw.代理佣金提现': 'ถอนค่านายหน้าตัวแทน',
  'wallt.withdraw.当前界面不可提现代理佣金部分': 'ส่วนค่านายหน้าตัวแทนไม่สามารถถอนในหน้าปัจจุบันได้! กรุณาไปที่หน้าตัวแทนเพื่อถอนค่านายหน้า!',
  'wallt.withdraw.每个人都可以参与的独立提款系统': 'ระบบถอนเงินอิสระที่ทุกคนสามารถเข้าร่วมได้',

  'wallt.transrecord.交易记录': 'บันทึกการทำธุรกรรม',
  'wallt.transrecord.充值': 'เติมเงิน',
  'wallt.transrecord.提现': 'ถอนเงิน',
  'wallt.transrecord.没有数据': 'ไม่มีข้อมูล',
  'wallt.transrecord.没有更多数据了': 'ไม่มีข้อมูลเพิ่มเติม',
  'wallt.transrecord.支付中': 'กำลังชำระเงิน',
  'wallt.transrecord.成功': 'สำเร็จ',
  'wallt.transrecord.失败': 'ล้มเหลว',
  'wallt.transrecord.处理中': 'กำลังดำเนินการ',
  'wallt.transrecord.类型': 'ประเภท',
  'wallt.transrecord.金额': 'จำนวนเงิน',
  'wallt.transrecord.渠道': 'ช่องทาง',
  'wallt.transrecord.状态': 'สถานะ',
}
