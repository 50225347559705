export default {
  'acting.代理': 'Aliança',
  'acting.详情': 'Detalhes',
  'acting.奖励': 'Prêmio',
  'acting.佣金': 'Comissão',
  'acting.规则': 'Regras',

  'acting.generalboard.邀请朋友来获取': 'Convide amigos para ganhar',
  'acting.generalboard.人': 'pessoas',
  'acting.generalboard.邀请奖励': 'Recompensa convite',
  'acting.generalboard.高达': 'Até',
  'acting.generalboard.佣金奖励': 'Recompensa Comissão',
  'acting.generalboard.描述': 'Ganhe {amount} por cada amigo convidado, além de até 90% de comissão nas apostas. Comece a ganhar agora!',
  'acting.generalboard.邀请链接': 'Link de Convite',
  'acting.generalboard.分享到社交平台': 'Compartilhar nas redes sociais',
  'acting.generalboard.拉新表现': 'Desempenho de recrutamento',
  'acting.generalboard.今日拉新': 'Registros de Hoje',
  'acting.generalboard.本周拉新': 'Registros desta Semana',
  'acting.generalboard.直属拉新表现': 'Registros por Convite Direto',
  'acting.generalboard.非直属拉新表现': 'Registros por Convite Indireto',
  'acting.generalboard.下级详情': 'Detalhes do Subordinado',
  'acting.generalboard.下级总人数': 'Número Total Subordinados',
  'acting.generalboard.直属下级人数': 'Subordinados Diretos',
  'acting.generalboard.非直属下级人数': 'Subordinados Indiretos',
  'acting.generalboard.总奖励': 'Recompensa Total',
  'acting.generalboard.总佣金': 'Comissão Total',
  'acting.generalboard.tip.描述': 'O número acima mostra quantos se registraram através do convite. O convidado precisa apostar no jogo para ser vinculado',
  'acting.generalboard.分享描述': 'Seja um agente, ganhe milhões por mês! Recompensa de convite {amount}, comissão de até 90%!',
  'acting.generalboard.tip.复制成功': 'Cópia bem-sucedida',
  'acting.generalboard.二维码标题': 'Comece a mudar sua vida agora mesmo',
  'acting.generalboard.二维码描述': 'Recrute agentes, junte-se a nós,e receba reembolsos super todos os dias',
  'acting.generalboard.保存': 'Salvar',
  'acting.generalboard.如何获得拉新奖励': 'Como ganhar recompensas e comissão?',
  'acting.generalboard.如何获得拉新奖励标题1': 'Compartilhe com seus amigos',
  'acting.generalboard.如何获得拉新奖励高亮文字1': 'Compartilhe',
  'acting.generalboard.如何获得拉新奖励描述1': 'Compartilhe o link de download ou o código QR com seus amigos',
  'acting.generalboard.如何获得拉新奖励标题2': 'Amigos Baixam e se Registram',
  'acting.generalboard.如何获得拉新奖励高亮文字2_1': 'Baixam',
  'acting.generalboard.如何获得拉新奖励高亮文字2_2': 'Registram',
  'acting.generalboard.如何获得拉新奖励描述2': 'Convide amigos para se registrar no aplicativo e se tornar membros',
  'acting.generalboard.如何获得拉新奖励标题3': 'Amigos Fazem Apostas',
  'acting.generalboard.如何获得拉新奖励高亮文字3': 'Fazem Apostas',
  'acting.generalboard.如何获得拉新奖励描述3': 'Vincule com sucesso usuários subordinados + ganhe recompensas por indicação',
  'acting.generalboard.如何获得拉新奖励标题4': 'Amigos Recarregam e Fazem Apostas',
  'acting.generalboard.如何获得拉新奖励高亮文字4_1': 'Recarregam',
  'acting.generalboard.如何获得拉新奖励高亮文字4_2': 'Fazem Apostas',
  'acting.generalboard.如何获得拉新奖励描述4': 'Desbloqueie recompensas por indicação + aproveite comissões de agente',
  'acting.reward.下级充值可解锁奖励': 'Recarregar em níveis baixos desbloqueia recompensas',
  'acting.reward.下级充值人数越多解锁速率越高': 'Mais subordinados recarregando, desbloqueio mais rápido',
  'acting.generalboard.保存成功': 'Gravado com sucesso',

  'acting.reward.邀请里程碑': 'Marcos de Convite',
  'acting.reward.邀请好友数量达到要求后可领取对应奖励': 'Receba a recompensa correspondente após atingir o número necessário de amigos convidados',
  'acting.reward.邀请': 'Convidar',
  'acting.reward.人': 'pessoas',
  'acting.reward.领取': 'Resgatar',
  'acting.reward.直属下级人数': 'Número Subordinados Diretos',
  'acting.reward.邀请奖励': 'Recompensa Convite',
  'acting.reward.直属下级绑定成功可获得': 'Receba ao conectar subordinados diretos com sucesso',
  'acting.reward.今日邀请': 'Convites Hoje',
  'acting.reward.总邀请': 'Total Convites',
  'acting.reward.今日奖励': 'Recompensa Hoje',
  'acting.reward.邀请总奖励': 'Recompensa Total Convite',
  'acting.reward.今日邀请人数': 'Número Convites Hoje',
  'acting.reward.邀请总人数': 'Número Total Convites',
  'acting.reward.用户ID': 'ID do Usuário',
  'acting.reward.昵称': 'Apelido',
  'acting.reward.奖励': 'Recompensa',
  'acting.reward.展示前50条信息': 'Exibindo as primeiras 50 informações',
  'acting.reward.暂无记录': 'Sem Registros',
  'acting.reward.领取成功': 'Resgate bem-sucedido',
  // ----------------------------------------------------------
  'acting.reward.未充值下级': 'Sem Recarga',
  'acting.reward.解锁明细': 'Desbloqueio',
  'acting.reward.拉新奖励领取规则': 'Regras para receber recompensa  convite',
  'acting.reward.充值金额': 'Valor recarregado',
  'acting.reward.解锁金额': 'Valor desbloqueio',
  'acting.reward.手机号': 'Número telefone',
  'acting.reward.拉新奖励': 'Convite',
  'acting.reward.充值': 'Recarregar',
  'acting.reward.解锁': 'Desbloquear',
  'acting.reward.实际': 'Real',
  'acting.reward.锁定奖励': 'Recompensa bloqueada',
  'acting.reward.可解锁奖励': 'Recompensa desbloqueável',
  'acting.reward.可领取': 'Reivindicável',
  'acting.reward.最高解锁': 'Desbloqueio máximo',
  'acting.reward.解锁公式': 'Fórmula desbloqueio',
  'acting.reward.解锁公式内容': 'Recarregamento do subordinado * Desbloqueio',
  'acting.reward.下级充值总人数': 'Subordinados que recarregaram',
  'acting.reward.当前速率': 'Taxa',
  'acting.reward.邀请下级获取拉新奖励': 'Convide subordinados para receber recompensa de convite {symbol}{amount}',
  'acting.reward.邀请新的代理绑定无需充值可直接解锁': 'Convide novos jogadores para vincular, desbloqueio direto sem recarga',

  'acting.commission.本周佣金信息': 'Informações Comissão Desta Semana',
  'acting.commission.下级总充值': 'Total de recargas',
  'acting.commission.直属下级佣金': 'Comissão de Subordinados Diretos',
  'acting.commission.非直属下级佣金': 'Comissão Subordinados Indiretos',
  'acting.commission.未领取佣金': 'Comissão não Resgatada',
  'acting.commission.领取佣金': 'Resgatar Comissão',
  'acting.commission.领取记录': 'Registro Resgates',
  // ----------------------------------------------------------
  'acting.commission.领取': 'Receber',
  'acting.commission.最多展示前三个月的信息': 'Mostra informações dos últimos 3 meses',
  'acting.commission.佣金规则': 'Regras de comissão',
  'acting.commission.上周下级充值流水': 'Rendimento anterior',
  'acting.commission.上周邀请人数': 'Convites anteriores',
  'acting.commission.佣金比例': 'Taxa comissão',
  'acting.commission.本周结佣比例依据上周下级表现计算': 'Taxa de comissão desta semana baseada no desempenho dos subordinados da semana passada',
  'acting.commission.本周详情': 'Detalhes desta semana',
  'acting.commission.本周下级充值流水': 'Rendimento desta semana',
  'acting.commission.本周下级充值金流水贡献': 'Contribuição recarga desta semana',
  'acting.commission.拉新人数': 'Novos convidados',
  'acting.commission.当前您享受最高佣金比例': 'Atualmente você recebe a maior taxa de comissão {rate}%',
  'acting.commission.完成以上条件下周可享受': 'Complete as condições acima para receber a taxa de comissão de {rate}% na próxima semana',
  'acting.commission.本周佣金比例': 'Comissão semana',
  'acting.commission.上周拉新人数': 'Novos convidados',
  'acting.commission.返佣比例': 'Taxa de reembolso',
  'acting.commission.本周直属下级佣金': 'Comissão direta',
  'acting.commission.本周非直属下级佣金': 'Comissão indireta',
  'acting.commission.之前未领取佣金': 'Comissão pendente',
  'acting.commission.本周佣金': 'Comissão semana',
  'acting.commission.周记录': 'Registro semanal',
  'acting.commission.时间': 'Tempo',
  'acting.commission.下级充值': 'Recarga subordinados',
  'acting.commission.邀请人数': 'Número convites',
  'acting.commission.佣金': 'Comissão',
}