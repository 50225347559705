export default {
    'login.banner.text1': 'Bem-vindo ao',
    'login.banner.text2': '7WIN.GAME',
    'login.banner.text3': 'Faça login para começar sua jornada de jogos',

    'login.login': 'Entrar',
    'login.telnumber': 'Número de Telefone',
    'login.password': 'Senha',
    'login.button': 'Entrar',
    'login.forgot': 'Esqueceu a senha?',
    'login.register.text': 'Não tem uma conta?',
    'login.register': 'Registre-se agora',
    'login.toast.请输入正确的手机号码': 'Por favor, insira um número de telefone válido',
    'login.toast.请输入密码': 'Por favor, insira a senha',
    'login.toast.密码不正确或者用户不存在': 'Senha incorreta ou usuário não encontrado',

    'register.register': 'Registrar-se',
    'register.telnumber': 'Número de Telefone',
    'register.password': 'Senha',
    'register.confirmpassword': 'Confirmar Senha',
    'register.agree': 'Eu concordo com',
    'register.agreement': 'Termos de Uso',
    'register.sendcode': 'Enviar Código de Verificação',
    'register.haveaccount': 'Já tem uma conta?',
    'register.login': 'Entrar agora',
    'register.code': 'Código de Verificação',
    'register.nocode': 'Não recebeu o código de verificação',
    'register.signin': 'Entrar',
    'register.toast.验证码已发送': 'Código de verificação enviado',
    'register.toast.请同意用户协议': 'Por favor, concorde com os Termos de Uso',
    'register.toast.请填写完整信息': 'Por favor, preencha todas as informações',
    'register.toast.请输入正确的手机号': 'Por favor, insira um número de telefone válido',
    'register.toast.密码不能少于6位': 'A senha deve ter pelo menos 6 caracteres',
    'register.toast.两次密码不一致': 'As senhas não coincidem',

    'find.forgot': 'Esqueceu a senha',
    'find.telnumber': 'Número de Telefone',
    'find.sendcode': 'Enviar Código de Verificação',
    'find.haveaccount': 'Já tem uma conta?',
    'find.login': 'Entrar agora',
    'find.password': 'Senha',
    'find.confirmpassword': 'Confirmar Senha',
    'find.resetpassword': 'Redefinir Senha',
    'find.code': 'Código de Verificação',
    'find.nocode': 'Não recebeu o código de verificação',
    'find.toast.请输入正确的手机号码': 'Por favor, insira um número de telefone válido',
    'find.toast.验证码已发送': 'Código de verificação enviado',
    'find.toast.该号码未注册': 'Este número não está registrado',
    'find.toast.频繁发送短信': 'Envio de SMS frequente',
    'find.toast.密码不能少于6位': 'A senha deve ter pelo menos 6 caracteres',
    'find.toast.两次密码不一致': 'As senhas não coincidem',
    'find.toast.请输入验证码': 'Por favor, insira o código de verificação',
    'find.toast.密码重置成功': 'Senha redefinida com sucesso',
    'find.toast.验证码不正确': 'Código de verificação incorreto',

    'agreement.title': 'Contrato usuário',
    'agreement.text': `
        7WIN.GAME é referido como "nós" ou "nosso".
Os jogadores são referidos como "você" ou "jogador".
"O site" refere-se ao 7WIN.GAME acessado através de plataformas de desktop, móveis ou outras plataformas.

1. Visão Geral

• 1.1. Este contrato de usuário se aplica ao uso dos jogos acessados através do 7WIN.GAME.
• 1.2. Este contrato de usuário entra em vigor imediatamente após você completar o processo de registro (incluindo marcar a opção para aceitar este contrato de usuário e criar sua conta com sucesso). Ao criar uma conta e usar qualquer parte deste site, você concorda com este contrato de usuário.
• 1.3. Antes de criar uma conta, você deve ler cuidadosamente todo o contrato de usuário. Se você não concordar com qualquer termo deste contrato, não deverá criar uma conta ou continuar usando este site.
• 1.4. Reservamo-nos o direito de modificar este contrato de usuário a qualquer momento, sem aviso prévio. Se realizarmos tais modificações, tomaremos as medidas apropriadas para chamar sua atenção para essas mudanças (por exemplo, por e-mail ou com um aviso destacado no site, além do contrato de usuário revisado), mas é sua responsabilidade verificar quaisquer modificações, atualizações e/ou alterações. O uso continuado do serviço e do site 7WIN.GAME após qualquer modificação será considerado como aceitação dessas modificações.
• 1.5. Este contrato de usuário pode ser publicado em vários idiomas para referência e conveniência dos jogadores. A versão em inglês é a única base legal entre você e nós. Se houver qualquer discrepância entre a tradução e a versão em inglês, a versão em inglês prevalecerá.

2. Declaração Vinculativa

2.1. Ao concordar em estar vinculado por este contrato de usuário, você também concorda em estar vinculado pelas regras do 7WIN.GAME e pela Política de Privacidade, que são incorporadas por referência neste contrato. Em caso de inconsistência, prevalecerá o contrato de usuário. Você declara e garante:

• 2.1.1. Você tem (a) 18 anos ou (b) a maioridade legal em sua jurisdição, o que for maior.
• 2.1.2. Você tem plena capacidade legal para celebrar um contrato vinculativo conosco e não está sujeito a nenhuma forma de limitação de capacidade legal.
• 2.1.3. Todas as informações fornecidas a nós durante a vigência deste contrato são verdadeiras, completas e precisas, e você deve nos notificar prontamente se houver mudanças.
• 2.1.4. Você é o único responsável por relatar e calcular qualquer imposto aplicável a qualquer prêmio recebido de nós, conforme as leis relevantes para sua jurisdição.
• 2.1.5. Você entende que ao usar nossos serviços, você pode perder os fundos depositados em sua conta de membro e concorda em assumir toda a responsabilidade por qualquer perda desse tipo.
• 2.1.6. Você está autorizado a usar os serviços do cassino online na jurisdição em que reside.
• 2.1.7. Você usará apenas moeda eletrônica válida e legalmente sua para depósitos e retiradas em sua conta de membro.
• 2.1.8. Você entende que o valor das criptomoedas pode variar significativamente de acordo com o valor de mercado.
• 2.1.9. O software de computador, gráficos de computador, site e interface de usuário fornecidos por nós são de nossa propriedade ou de nossos afiliados e são protegidos por leis de direitos autorais. Você pode usar este software apenas para fins de entretenimento pessoal, de acordo com nossas regras, o contrato de usuário e todas as leis, regras e regulamentos aplicáveis.
• 2.1.10. Você entende que as criptomoedas não são consideradas moedas legais ou dinheiro, portanto, no site, elas são tratadas como moedas virtuais sem valor intrínseco.
• 2.1.11. Você confirma que não é um executivo sênior, diretor, funcionário, consultor ou agente do 7WIN.GAME, nem funcionário de qualquer empresa associada ao 7WIN.GAME, e também não é parente ou cônjuge de qualquer uma dessas pessoas.
• 2.1.12. Você não foi diagnosticado ou classificado como um jogador compulsivo ou problemático. Caso um problema de jogo seja detectado durante o uso de nossos serviços, não nos responsabilizamos, mas tentaremos fornecer o suporte necessário. Se acharmos necessário, reservamo-nos o direito de implementar um período de reflexão.
• 2.1.13. Você reconhece e aceita que temos o direito de detectar e bloquear o uso de tecnologias proibidas, incluindo, mas não se limitando a, detecção de transações fraudulentas, registros e registros automáticos, tecnologias de jogos e captura de tela. Estas ações podem incluir, mas não se limitam a, verificação das propriedades do dispositivo do jogador, detecção de localização geográfica e bloqueio de IP, análise de transações e blockchain.
• 2.1.14. Você aceita que temos o direito de suspender e/ou alterar quaisquer jogos ou atividades oferecidos no site e de recusar ou limitar apostas.
• 2.1.15. Você concorda que podemos proibir/bloquear múltiplas contas e controlar livremente os ativos em tais contas.
• 2.1.16. Você entende que erros ou defeitos no software podem existir, e concorda em não tirar proveito desses erros. Além disso, você concorda em relatar imediatamente ao 7WIN.GAME qualquer erro ou incompletude. Se você não cumprir com esta obrigação, o 7WIN.GAME tem o direito de exigir o reembolso total de todas as despesas associadas a erros ou incompletudes, incluindo quaisquer custos gerados por erros/incompletudes ou mensagens falhas.
• 2.1.17. Você entende que o 7WIN.GAME tem o direito de realizar o processo "KYC" (Conheça Seu Cliente). Se determinarmos que você forneceu informações falsas ou enganosas, podemos bloquear ou encerrar seu acesso à sua conta de usuário.
    `,

    'login.tip.账号在其它地方被登录': 'Conta foi acessada em outro local',
};