export default {
  "mine.退出登录": "Keluar",
  "mine.用户ID": "ID Pengguna",
  "mine.XP到下一个等级": "XP ke Level Berikutnya",
  "mine.vip": "VIP",
  "mine.充值": "Isi Ulang",
  "mine.下注": "Taruhan",

  "mine.menus.充值": "Isi Ulang",
  "mine.menus.提现": "Penarikan",
  "mine.menus.账户明细": "Detail Akun",
  "mine.menus.代理": "Agen",
  "mine.menus.每日签到": "Check-in Harian",
  "mine.menus.vip": "VIP",
  "mine.menus.下注记录": "Catatan Taruhan",
  "mine.menus.邮件通知": "Pemberitahuan Email",
  "mine.menus.注册送": "Bonus Pendaftaran",
  "mine.menus.tip.活动未开启": "Acara Belum Dimulai",

  "mine.userdetail.用户ID": "ID Pengguna",
  "mine.userdetail.总输赢": "Total Menang/Kalah",
  "mine.userdetail.总下注": "Total Taruhan",
  "mine.userdetail.仅计算当月数据": "Hanya menghitung data bulan ini",
  "mine.userdetail.最喜欢玩的3款游戏": "3 Game Favorit",
  "mine.userdetail.用户信息": "Informasi Pengguna",
  "mine.userdetail.绑定手机": "Hubungkan Ponsel",
  "mine.userdetail.绑定邮箱": "Hubungkan Email",
  "mine.userdetail.更换": "Ganti",
  "mine.userdetail.绑定": "Hubungkan",

  "mine.replacename.用户名修改": "Ubah Nama Pengguna",
  "mine.replacename.用户名": "Nama Pengguna",
  "mine.replacename.请输入用户名": "Silakan masukkan nama pengguna",
  "mine.replacename.确认": "Konfirmasi",
  "mine.replacename.tip.请输入用户名": "Silakan masukkan nama pengguna",

  "mine.replaceemail.绑定邮箱": "Hubungkan Email",
  "mine.replaceemail.邮箱": "Email",
  "mine.replaceemail.请输入邮箱": "Silakan masukkan email",
  "mine.replaceemail.确认": "Konfirmasi",
  "mine.replaceemail.tip.请输入邮箱": "Silakan masukkan email",
  "mine.replaceemail.tip.请输入正确的邮箱地址": "Silakan masukkan alamat email yang benar",

  "mine.betrecord.下注记录": "Catatan Taruhan",
  "mine.betrecord.最多展示前100条下注详情": "Menampilkan hingga 100 detail taruhan pertama",
  "mine.betrecord.暂无下注记录": "Tidak ada catatan taruhan",
  "mine.betrecord.下注号": "Nomor Taruhan",
  "mine.betrecord.时间": "Waktu",
  "mine.betrecord.下注金额": "Jumlah Taruhan",
  "mine.betrecord.输赢": "Menang/Kalah",

  "mine.email.邮件通知": "Pemberitahuan Email",
  "mine.email.已领取": "Sudah Diterima",
  "mine.email.未领取": "Belum Diterima",
  "mine.email.收起": "Tutup",
  "mine.email.展开": "Buka",
  "mine.email.暂无邮件": "Tidak ada email",
  "mine.email.type.VIP返利提取成功": "Pengembalian VIP",
  "mine.email.value.VIP返利提取成功": "Berhasil menerima pengembalian sebesar {amount}",
  "mine.email.type.幸运签到": "Check-in Harian",
  "mine.email.value.幸运签到": "Koin hadiah sebesar {amount} telah dikreditkan",
  "mine.email.type.NEWCOMER SPECIAL": "Bonus Pendaftaran",
  "mine.email.value.NEWCOMER SPECIAL": "Terima kasih telah mengikuti acara bonus pendaftaran! Bonus sebesar {amount} telah dikunci! Bertaruh dengan saldo isi ulang untuk membuka bonus ini! Tidak ada persyaratan turnover untuk penarikan! Lihat detail acara untuk info lebih lanjut.",
  "mine.email.type.提款成功": "Penarikan Berhasil",
  "mine.email.value.提款成功": "Selamat, Anda telah berhasil menarik {amount}. Jika status penarikan berhasil tapi Anda belum benar-benar menerima pembayaran, bank biasanya memindahkan dana dalam 24 jam. Jika Anda tidak menerima pembayaran setelah 24 jam, silakan masukkan permainan dan kontak layanan pelanggan!",
  "mine.email.type.提款失败": "Penarikan Gagal",
  "mine.email.value.提款失败": "Maaf, penarikan Anda gagal. Mungkin karena kesalahan nomor kartu atau pemeliharaan saluran. Silakan hubungi dukungan pelanggan.",
  "mine.email.type.提现扣除活动赠送的金币": "Penarikan dengan Pengurangan Bonus Koin",
  "mine.email.value.提现扣除活动赠送的金币": "Penarikan dengan pengurangan bonus koin: {amount}",
  "mine.email.tip.领取成功": "Berhasil Diterima",
  "mine.email.type.Recharge Rebates": "Rebate Pengisian Ulang",
  "mine.email.value.Recharge Rebates": "Terima kasih telah berpartisipasi dalam acara rebate isi ulang! Jumlah bonus {1} telah dikunci! Pasang taruhan dengan dana isi ulang untuk membukanya! Koin yang dibuka dapat ditarik tanpa persyaratan taruhan! Untuk mempelajari lebih lanjut tentang aturan, silakan periksa detail acara!",
  "mine.email.type.Recharge Rebate Activity Reminder": "Aktivitas Rebate Isi Ulang",
  "mine.email.value.Recharge Rebate Activity Reminder": "Pengguna yang Terhormat,Terima kasih telah bergabung dengan permainan kami dan menyelesaikan pengisian pertama Anda! Sesuai dengan aturan kegiatan kami, saldo bonus pengalaman yang Anda terima saat pendaftaran telah dihapus. Kami menghargai dukungan dan pengertian Anda.Kami berharap Anda meraih lebih banyak kemenangan besar dalam permainan! Jika Anda memiliki pertanyaan, jangan ragu untuk menghubungi tim layanan pelanggan kami. Kami selalu siap membantu Anda.",

  "mine.accountdetail.账户明细": "Detail Akun",
  "mine.accountdetail.可提现": "Dapat Ditarik",
  "mine.accountdetail.金额": "Jumlah",
  "mine.accountdetail.充值金币": "Koin Isi Ulang",
  "mine.accountdetail.签到金币": "Koin Check-in",
  "mine.accountdetail.充值金详情": "Detail Koin Isi Ulang",
  "mine.accountdetail.签到金详情": "Detail Koin Check-in",
  "mine.accountdetail.提现": "Penarikan",
  "mine.accountdetail.流水": "Turnover",
  "mine.accountdetail.余额": "Saldo",
  "mine.accountdetail.无流水要求": "Tidak ada persyaratan turnover",
  "mine.accountdetail.流水倍数": "Turnover * {rate}",
  "mine.accountdetail.加载中": "Memuat",
  "mine.accountdetail.desc.13014": "Koin tidak dapat ditarik. Setelah menyelesaikan turnover 0,1 kali, Anda dapat mengikuti acara dan menarik!",

  "mine.vip.VIP详情": "Detail VIP",
  "mine.vip.成为VIP您可获得以下奖励": "Menjadi VIP untuk mendapatkan hadiah berikut",
  "mine.vip.VIP奖金": "Bonus VIP",
  "mine.vip.提现福利": "Keuntungan",
  "mine.vip.下注返利": "Pengembalian Taruhan",
  "mine.vip.XP到下一个等级": "XP ke Level Berikutnya",
  "mine.vip.充值": "Isi Ulang",
  "mine.vip.提现": "Penarikan",
  "mine.vip.下注": "Taruhan",
  "mine.vip.成为VIP可获取更多福利": "Menjadi VIP untuk mendapatkan lebih banyak keuntungan!",
  "mine.vip.完成以下任意任务即可成为VIP": "Selesaikan salah satu tugas berikut untuk menjadi VIP",
  "mine.vip.充值xx以上": "Isi ulang lebih dari {amount}",
  "mine.vip.完成一次充值+一次提现": "Selesaikan satu isi ulang + satu penarikan",
  "mine.vip.代理收入达到xx以上": "Pendapatan agen melebihi {amount}",
  "mine.vip.维持当前VIP要求": "Persyaratan untuk mempertahankan VIP",
  "mine.vip.下注金额超过xx": " Taruhan melebihi {symbol}{amount}",
  "mine.vip.当前VIP享受以下福利": "VIP saat ini menikmati keuntungan berikut",
  "mine.vip.升级奖金": "Bonus Peningkatan",
  "mine.vip.可获得xx": "Dapatkan {symbol}{amount}",
  "mine.vip.领取": "Klaim",
  "mine.vip.已领取": "Sudah Diterima",
  "mine.vip.本周充值返利": "Pengembalian Isi Ulang Mingguan",
  "mine.vip.最小充值": "Isi ulang minimum",
  "mine.vip.去充值": "Pergi ke Isi Ulang",
  "mine.vip.提现返利": "Keuntungan",
  "mine.vip.每日最高可提现": "Penarikan Harian Maksimal",
  "mine.vip.最高可提现": "Penarikan tunai maksimal",
  "mine.vip.提现进度": "Kemajuan penarikan",
  "mine.vip.游戏下注返利": "Pengembalian Taruhan Permainan",
  "mine.vip.slot": "Slot",
  "mine.vip.棋牌": "Permainan Kartu",
  "mine.vip.视讯": "Siaran Langsung",
  "mine.vip.体育": "Olahraga",
}
