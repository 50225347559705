export default {
  'acting.代理': '代理',

  'acting.详情': '详情',
  'acting.奖励': '奖励',
  'acting.佣金': '佣金',
  'acting.规则': '规则',

  'acting.generalboard.邀请朋友来获取': '邀请好友可获取',
  'acting.generalboard.人': '人',
  'acting.generalboard.邀请奖励': '邀请奖励',
  'acting.generalboard.高达': '至高',
  'acting.generalboard.佣金奖励': '佣金奖励',
  'acting.generalboard.描述': 'Get {amount} for each friend you invite, plus a up to 90% commission on their wagers. Enjoy consistent commissions, whether they win or lose. Start earning now!',
  'acting.generalboard.邀请链接': '邀请链接',
  'acting.generalboard.分享到社交平台': '分享到社交平台',
  'acting.generalboard.拉新表现': '拉新表现(注册成功)',
  'acting.generalboard.今日拉新': '今日注册',
  'acting.generalboard.本周拉新': '本周注册',
  'acting.generalboard.直属拉新表现': '直属拉新注册',
  'acting.generalboard.非直属拉新表现': '非直属拉新注册',
  'acting.generalboard.下级详情': '下级详情(绑定成功)',
  'acting.generalboard.下级总人数': '下级总人数',
  'acting.generalboard.直属下级人数': '直属下级人数',
  'acting.generalboard.非直属下级人数': '非直属下级人数',
  'acting.generalboard.总奖励': '总奖励',
  'acting.generalboard.总佣金': '总佣金',
  'acting.generalboard.tip.描述': '以上人数代表邀请人成功注册游戏的人数,下级绑定成还需游戏中下注',
  'acting.generalboard.分享描述': '成为代理,月入百万!邀请奖励{amount},佣金高达90%!',
  'acting.generalboard.tip.复制成功': '复制成功',
  'acting.generalboard.二维码标题': '从现在立即开始改变生活',
  'acting.generalboard.二维码描述': '招募代理,加入我们,每天超级返利',
  'acting.generalboard.保存': '保存',
  'acting.generalboard.如何获得拉新奖励': '如何获得拉新奖励+佣金?',
  'acting.generalboard.如何获得拉新奖励标题1': '分享给你的朋友',
  'acting.generalboard.如何获得拉新奖励高亮文字1': '分享',
  'acting.generalboard.如何获得拉新奖励描述1': '将下载链接或二维码分享给你的好友',
  'acting.generalboard.如何获得拉新奖励标题2': '朋友下载并注册',
  'acting.generalboard.如何获得拉新奖励高亮文字2_1': '下载',
  'acting.generalboard.如何获得拉新奖励高亮文字2_2': '注册',
  'acting.generalboard.如何获得拉新奖励描述2': '邀请好友注册app成为会员',
  'acting.generalboard.如何获得拉新奖励标题3': '朋友下注',
  'acting.generalboard.如何获得拉新奖励高亮文字3': '下注',
  'acting.generalboard.如何获得拉新奖励描述3': '成功绑定下级+获取拉新奖金',
  'acting.generalboard.如何获得拉新奖励标题4': '朋友充值并下注',
  'acting.generalboard.如何获得拉新奖励高亮文字4_1': '充值',
  'acting.generalboard.如何获得拉新奖励高亮文字4_2': '下注',
  'acting.generalboard.如何获得拉新奖励描述4': '解锁拉新奖励+享受代理佣金',
  'acting.generalboard.保存成功': '保存成功',

  'acting.reward.邀请里程碑': '邀请里程碑',
  'acting.reward.邀请好友数量达到要求后可领取对应奖励': '邀请好友数量达到要求后可领取对应奖励',
  'acting.reward.邀请': '邀请',
  'acting.reward.人': '人',
  'acting.reward.领取': '领取',
  'acting.reward.直属下级人数': '直属下级人数',
  'acting.reward.邀请奖励': '邀请奖励',
  'acting.reward.直属下级绑定成功可获得': '直属下级绑定成功可获得',
  'acting.reward.今日邀请': '今日邀请',
  'acting.reward.总邀请': '总邀请',
  'acting.reward.今日奖励': '今日奖励',
  'acting.reward.邀请总奖励': '邀请总奖励',
  'acting.reward.今日邀请人数': '今日邀请人数',
  'acting.reward.邀请总人数': '邀请总人数',
  'acting.reward.用户ID': '用户ID',
  'acting.reward.昵称': '昵称',
  'acting.reward.奖励': '奖励',
  'acting.reward.展示前50条信息': '展示前50条信息',
  'acting.reward.暂无记录': '暂无记录',
  'acting.reward.领取成功': '领取成功',
  // -------------------------------------------------------
  'acting.reward.未充值下级': '未充值下级',
  'acting.reward.解锁明细': '解锁明细',
  'acting.reward.拉新奖励领取规则': '拉新奖励领取规则',
  'acting.reward.充值金额': '充值金额',
  'acting.reward.解锁金额': '解锁金额',
  'acting.reward.手机号': '手机号',
  'acting.reward.拉新奖励': '拉新奖励',
  'acting.reward.充值': '充值',
  'acting.reward.解锁': '解锁',
  'acting.reward.实际': '实际',
  'acting.reward.锁定奖励': '锁定奖励',
  'acting.reward.可解锁奖励': '可解锁奖励',
  'acting.reward.可领取': '可领取',
  'acting.reward.最高解锁': '最高解锁',
  'acting.reward.解锁公式': '解锁公式',
  'acting.reward.解锁公式内容': '单个下级充值金*解锁速率',
  'acting.reward.下级充值总人数': '下级充值总人数',
  'acting.reward.当前速率': '当前速率',
  'acting.reward.邀请下级获取拉新奖励': '邀请下级获取{symbol}{amount}拉新奖励',
  'acting.reward.邀请新的代理绑定无需充值可直接解锁': '邀请新的代理绑定无需充值可直接解锁',
  'acting.reward.下级充值可解锁奖励': '下级充值可解锁奖励',
  'acting.reward.下级充值人数越多解锁速率越高': '下级充值人数越多解锁速率越高',


  'acting.commission.本周佣金信息': '本周佣金信息',
  'acting.commission.下级总充值': '下级总充值',
  'acting.commission.直属下级佣金': '直属下级佣金',
  'acting.commission.非直属下级佣金': '非直属下级佣金',
  'acting.commission.未领取佣金': '未领取佣金',
  'acting.commission.领取佣金': '领取佣金',
  'acting.commission.领取记录': '领取记录',
  'acting.commission.佣金总和': '佣金总和',
  'acting.commission.领取时间': '领取时间',
  'acting.commission.直属佣金': '直属佣金',
  'acting.commission.非直属佣金': '非直属佣金',
  'acting.commission.总和': '总和',
  'acting.commission.展示前50条信息': '展示前50条信息',
  'acting.commission.暂无记录': '暂无记录',
  'acting.commission.领取成功': '领取成功',
  // -------------------------------------------------------
  'acting.commission.领取': '领取',
  'acting.commission.最多展示前三个月的信息': '最多展示前三个月的信息',
  'acting.commission.佣金规则': '佣金规则',
  'acting.commission.上周下级充值流水': '上周下级充值流水',
  'acting.commission.上周邀请人数': '上周邀请人数',
  'acting.commission.佣金比例': '佣金比例',
  'acting.commission.本周结佣比例依据上周下级表现计算': '本周结佣比例依据上周下级表现计算',
  'acting.commission.本周详情': '本周详情',
  'acting.commission.本周下级充值流水': '本周下级充值流水',
  'acting.commission.本周下级充值金流水贡献': '本周下级充值金流水贡献',
  'acting.commission.拉新人数': '拉新人数',
  'acting.commission.当前您享受最高佣金比例': '当前您享受最高佣金比例{rate}%',
  'acting.commission.完成以上条件下周可享受': '完成以上条件,下周可享受{rate}%佣金比例',
  'acting.commission.本周佣金比例': '本周佣金比例',
  'acting.commission.上周拉新人数': '上周拉新人数',
  'acting.commission.返佣比例': '返佣比例',
  'acting.commission.本周直属下级佣金': '本周直属下级佣金',
  'acting.commission.本周非直属下级佣金': '本周非直属下级佣金',
  'acting.commission.之前未领取佣金': '之前未领取佣金',
  'acting.commission.本周佣金': '本周佣金',
  'acting.commission.周记录': '周记录',
  'acting.commission.时间': '时间',
  'acting.commission.下级充值': '下级充值',
  'acting.commission.邀请人数': '邀请人数',
  'acting.commission.佣金': '佣金',
}