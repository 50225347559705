export default {
  'wallt.充值': 'Depósito',
  'wallt.提现': 'Saque',

  'wallt.recharge.充值方式': 'Forma Depósito',
  'wallt.recharge.充值金额': 'Valor',
  'wallt.recharge.确认充值': 'Confirmar',
  'wallt.recharge.请输入银行账户': 'Informe a Conta Bancária',
  'wallt.recharge.请输入开户名': 'Informe o Nome do Titular',
  'wallt.recharge.请输入手机号': 'Informe o Número de Telefone',
  'wallt.recharge.输入您的实际付款Momo帐户以完成付款': 'Insira sua conta Momo real para concluir o pagamento',
  'wallt.recharge.请准确填写您的支付账户,填写错误可能会导致转账资金的损失': 'Preencha corretamente sua conta de pagamento para evitar perda de fundos',
  'wallt.recharge.填写正确的银行相关信息可提高充值成功率': 'Preencher as informações bancárias corretamente aumenta a taxa de sucesso do depósito',
  'wallt.recharge.汇率': 'Taxa',
  'wallt.recharge.参与活动': 'Participar',
  'wallt.recharge.注册送': 'Novos',
  'wallt.recharge.签到': 'Check-in',
  'wallt.recharge.当前参与活动': 'Atividade Atual',
  'wallt.recharge.充值额度': 'Limite Depósito',
  'wallt.recharge.赠送额度': 'Bonus',
  'wallt.recharge.您还不能参加此活动': 'Não pode participar ainda',
  'wallt.recharge.下订单失败': 'Pedido Falhou',
  'wallt.recharge.下订单失败，请尝试大额支付或其他支付通道!': 'Pedido falhou, tente um pagamento maior ou outro método!',
  'wallt.recharge.当前充值渠道正在维护': 'Canal de depósito em manutenção',
  'wallt.recharge.当前银行正在维护': 'Banco em manutenção no momento',
  'wallt.recharge.您当前不能参与活动确认要充值吗': 'Você não pode participar da atividade. Confirmar depósito?',
  'wallt.recharge.选择银行卡': 'Escolha o Cartão Bancário',
  'wallt.recharge.充值': 'Depósito',
  'wallt.recharge.手续费': 'Taxa Serviço',
  'wallt.recharge.需要扣除手续费': 'Será cobrada uma taxa de serviço',
  'wallt.recharge.实际到账': 'Valor Recebido',
  'wallt.recharge.你不能参与任何活动,还要遭扣除手续费': 'Este método não suporta nenhuma promoção! Se o valor pago for superior ao exigido, a diferença não será reembolsada',
  'wallt.recharge.确认': 'Confirmar',
  'wallt.recharge.二维码描述': 'Se não conseguir usar o código QR, copie as informações abaixo para fazer a transferência',
  'wallt.recharge.银行名称': 'Nome Banco',
  'wallt.recharge.账户': 'Conta',
  'wallt.recharge.账户名称': 'Nome Conta',
  'wallt.recharge.转账金额': 'Valor Transferência',
  'wallt.recharge.复制': 'Copiar',
  'wallt.recharge.注意': 'Aviso',
  'wallt.recharge.请充值相应金额的USDT!不正确的充值金额将导致游戏币无法充值!': 'Por favor, recarregue o valor correto em USDT! Recargas incorretas resultarão na falha ao creditar as moedas do jogo!',
  'wallt.recharge.您有一笔订单未支付': 'Você tem um pedido não pago',
  'wallt.recharge.规则1': '1. Certifique-se de inserir as informações corretas. Informações incorretas não serão processadas automaticamente',
  'wallt.recharge.规则2': '2. Se não conseguir usar o código QR, copie os detalhes do pagamento',
  'wallt.recharge.规则3': '3. Não atualize a página durante o processo de transferência.',
  'wallt.recharge.认证': 'Sertifikasi PCI DSS',
  'wallt.recharge.认证描述': 'Memenuhi standar keamanan internasional untuk keamanan data keuangan',
  'wallt.rechargechannel.网关': 'Gateway',
  'wallt.rechargechannel.银行转账': 'Transfer Bank',
  'wallt.rechargechannel.ZaloPay': 'ZaloPay',
  'wallt.rechargechannel.VIETQR': 'VIETQR',
  'wallt.rechargechannel.MomoPay': 'MomoPay',
  'wallt.rechargechannel.Nạp thẻ cào': 'Isi Ulang Kartu',
  'wallt.rechargechannel.VTPay': 'VTPay',
  'wallt.rechargechannel.银行扫码': 'Scan QR Bank',

  'wallt.withdraw.绑定银行卡': 'Vincular conta saque',
  'wallt.withdraw.更换银行卡': 'Trocar conta saque',
  'wallt.withdraw.提现信息': 'Dados de Retirada',
  'wallt.withdraw.最高可提现金额': 'Limite de Retirada',
  'wallt.withdraw.当前选择金额': 'Valor Selecionado',
  'wallt.withdraw.服务费': 'Taxa Serviço',
  'wallt.withdraw.收到金额': 'Valor Recebido',
  'wallt.withdraw.手续费': 'Taxa Serviço',
  'wallt.withdraw.当前提现金额大于最大可提现金额': 'O valor de retirada atual é maior do que o limite máximo permitido',
  'wallt.withdraw.提现金额': 'Valor Retirada',
  'wallt.withdraw.当前提现渠道正在维护': 'O canal de retirada atual está em manutenção',
  'wallt.withdraw.确定': 'Confirmar',
  'wallt.withdraw.当前金额正在维护': 'O valor atual está em manutenção',
  'wallt.withdraw.当前提现金额不符合最大可提现金额': 'O valor de retirada atual não está dentro do limite máximo permitido',
  'wallt.withdraw.不满足VIP提款金额要求请升级VIP': 'Não atende ao requisito de valor de saque VIP! Por favor, faça o upgrade para VIP!',
  'wallt.withdraw.不满足vip1提现金额要求请充值': 'Não atende aos requisitos atuais de saque VIP! Recarregue R$150 para se tornar VIP2 e sacar o valor total!',
  'wallt.withdraw.您的余额已满足提现要求可以立即提现': 'Seu saldo atingiu o requisito para saque! Torne-se VIP para sacar imediatamente!',
  'wallt.withdraw.您的余额已满足提现要求可以立即提现3': 'Recarregue R$100 para se tornar VIP2! VIP2 pode sacar o valor total imediatamente!',
  'wallt.withdraw.您的余额已满足提现要求可以立即提现4': 'Você pode sacar R$10 imediatamente! Por favor, saque agora!',
  'wallt.withdraw.您的余额已满足提现要求可以立即提现5': 'Recarregue mais R$50 para sacar o valor total',
  'wallt.withdraw.成为VIP后可以立即提现': 'Após se tornar VIP, pode sacar imediatamente',
  'wallt.withdraw.请选择银行': 'Selecione Banco',
  'wallt.withdraw.请输入您的姓名': 'Digite seu nome',
  'wallt.withdraw.请输入您的银行卡号': 'Digite o número do cartão',
  'wallt.withdraw.请输入您的钱包地址': 'Digite o endereço da sua carteira',
  'wallt.withdraw.请输入您的电子邮件': 'Digite seu e-mail',
  'wallt.withdraw.请输入提款密码': 'Por favor insira a senha de retirada',
  'wallt.withdraw.请确认提款密码': 'Confirmar senha de retirada',
  'wallt.withdraw.请检查表单是否填写完整': 'Por favor verifique se o formulário está completamente preenchido',
  'wallt.withdraw.请输入正确的邮箱': 'Por favor insira o endereço de e-mail correto',
  'wallt.withdraw.密码不能少于6位': 'A senha não pode ter menos de 6 caracteres',
  'wallt.withdraw.两次密码输入不一致': 'As duas entradas de senha são inconsistentes',
  'wallt.withdraw.提款密码': 'Senha de retirada',
  'wallt.withdraw.忘记密码': 'esqueça a senha',
  'wallt.withdraw.请输入密码': 'Por favor insira a senha',
  'wallt.withdraw.更换银行卡需要验证交易密码': 'Trocar o cartão bancário requer verificação da senha de transação',
  'wallt.withdraw.密码错误': 'Senha incorreta',
  'wallt.withdraw.服务器内部错误': 'Erro interno do servidor',
  'wallt.withdraw.短信验证': 'Verificação por SMS',
  'wallt.withdraw.手机号': 'Número telefone',
  'wallt.withdraw.请输入验证码': 'Digite o código de verificação',
  'wallt.withdraw.发送': 'Enviar',
  'wallt.withdraw.验证码发送成功': 'Código de verificação enviado com sucesso',
  'wallt.withdraw.验证码错误': 'Código de verificação incorreto',
  'wallt.withdraw.发送验证码': 'Enviar código de verificação',
  'wallt.withdraw.br.绑定提款账号': 'Vincular conta saque',
  'wallt.withdraw.br.请选择取款方式': 'Escolha o método de retirada',
  'wallt.withdraw.br.请输入用户姓名': 'Digite o nome do usuário',
  'wallt.withdraw.br.请输入取款账号': 'CPF(Digite o CPF)',
  'wallt.withdraw.br.请输入邮箱': 'Digite o e-mail',
  'wallt.withdraw.br.请输入手机号': 'Digite o número de telefone',
  'wallt.withdraw.br.请输入正确的CPF': 'Formato de CPF inválido! Insira um CPF correto!',

  'wallt.withdraw.可提现佣金': 'Comissão disponível para saque',
  'wallt.withdraw.代理佣金提现': 'Retirada comissão agente',
  'wallt.withdraw.当前界面不可提现代理佣金部分': 'A comissão de agente não pode ser retirada aqui! Vá para a página do agente para retirar',
  'wallt.withdraw.每个人都可以参与的独立提款系统': 'Sistema de saque independente do qual todos podem participar',

  'wallt.transrecord.交易记录': 'Histórico de transações',
  'wallt.transrecord.充值': 'Depósito',
  'wallt.transrecord.提现': 'Saque',
  'wallt.transrecord.没有数据': 'Sem dados',
  'wallt.transrecord.没有更多数据了': 'Sem mais dados',
  'wallt.transrecord.支付中': 'Pagamento em andamento',
  'wallt.transrecord.成功': 'Bem-sucedido',
  'wallt.transrecord.失败': 'Falhou',
  'wallt.transrecord.处理中': 'Processando',
  'wallt.transrecord.类型': 'Tipo',
  'wallt.transrecord.金额': 'Montante',
  'wallt.transrecord.渠道': 'Canal',
  'wallt.transrecord.状态': 'Status',

}
