<template>
    <van-overlay :show="is_show && !is_show_home_guide" z-index="1000">
        <div class="wrapper" @click.stop>
            <div class="block">

                <div class="header">
                    <div class="title">{{ i18n.t('public.通知') }}</div>
                    <div class="close" @click="is_show = false">
                        <van-icon name="cross" color="#8C86B2" size="24" />
                    </div>
                </div>

                <div class="content">
                    <div class="desc">
                        {{ show_desc }}
                    </div>
                    <div class="button" @click="is_show = false">{{ i18n.t('public.确认') }}</div>
                </div>

            </div>
        </div>
    </van-overlay>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, onBeforeUnmount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { menuNotice } from './menuType';
import evbus from '@/utils/mitt';
import { useStore } from 'vuex';
import { closeToast } from 'vant';
import { matchString,goldFormat, isBrShowFirstGuide } from "@/utils/function";
import { IMGetInfoActivite } from '@/utils/im';
import { useRoute } from 'vue-router';

const i18n = useI18n();
const store = useStore();
const route = useRoute();

const is_show = ref(false);
// 通知类型
const menu_key = ref('');
// 通知内容
const real_desc = ref('');
const show_desc = computed(() => {
    return menuNotice.value[menu_key.value] ? menuNotice.value[menu_key.value] : menu_key.value;
});

const is_show_home_guide = computed(() => {
    if (route.name === 'home') {
        return isBrShowFirstGuide();
    }
    return false;
});

onBeforeMount(()=>{
    evbus.on('websoket_message',onWebsoketMessage);
});

onBeforeUnmount(()=>{
    evbus.off('websoket_message',onWebsoketMessage);
});



// 处理seokt消息
const onWebsoketMessage = (message:any) => {
    // 被挤下线
    if(message.cmd_name === 'Pmd.ReconnectKickoutLoginUserPmd_S'){
        closeToast();

        if(message.desc.includes('The system has detected that your account may be logged in on multiple devices') && message.desc.includes('please change your account password promptly')){
            menu_key.value = '账号被其它设备登录';
        }else{
            menu_key.value = '账号在其它地方被登录';
        };

        is_show.value = true;
    };

    // 充值次数+1
    if(message.do === 'Cmd.UserInfoGetLobbyCmd_S' && message.data?.rechargeChips){
        const userinfo = JSON.parse(JSON.stringify(store.state.user.userinfo));
        userinfo.rechargeTimes ? userinfo.rechargeTimes++ : userinfo.rechargeTimes = 1;
        store.commit('user/setUserInfo',userinfo);
        IMGetInfoActivite();
    };

    // 通知提示
    if(message.do === 'Cmd.SysMessageMahjongLobbyCmd_S' || message.do === 'Cmd.SysMessageCmd_S'){
        closeToast();

        menu_key.value = message.data.desc || message.data.msg;
        real_desc.value = message.data.desc || message.data.msg;

        // 当前提现银行正在维护
        if(menu_key.value.includes('The current cash withdrawal bank:') && menu_key.value.includes('please change your bank card and try again!')){
            let values_arr = matchString(real_desc.value);  // 获取动态值
            // 如果有menuNotice有对应提示翻译，menuNotice.value展示文案 = 翻译+动态值
            menuNotice.value['正在维护'] = i18n.t('notice.正在维护', { channel: values_arr[0] });
            // menu_key.value = 翻译KEY
            menu_key.value = '正在维护';
        };

        // 领取新版注册送金币
        if(menu_key.value.includes('Successfully received') && menu_key.value.includes('gold coins')){
            let values_arr = matchString(real_desc.value);  // 获取动态值
            menuNotice.value['领取新版注册送金币'] = i18n.t('notice.领取新版注册送金币', { amount: goldFormat(values_arr[0]) });
            menu_key.value = '领取新版注册送金币';
        };

        // 领取新版注册送金币 最后一次领取
        if(menu_key.value.includes('You have successfully received') && menu_key.value.includes('the newbie special event will be offline soon. Thank you for your support!')){
            let values_arr = matchString(real_desc.value);  // 获取动态值
            menuNotice.value['领取新版注册送金币最后一次领取'] = i18n.t('notice.领取新版注册送金币', { amount: goldFormat(values_arr[0]) });
            menu_key.value = '领取新版注册送金币最后一次领取';
        };

        // 领取新版注册送金币 最后一次领取2
        if(menu_key.value.includes('You’ve successfully claimed') && menu_key.value.includes('Your recharge rebate coins have been fully claimed. Please wait for the next event!')){
            let values_arr = matchString(real_desc.value);  // 获取动态值
            menuNotice.value['领取新版注册送金币最后一次领取2'] = i18n.t('notice.领取新版注册送金币最后一次领取2', { amount: goldFormat(values_arr[0]) });
            menu_key.value = '领取新版注册送金币最后一次领取2';
        };

        // 提现vip等级不够
        if(menu_key.value.includes('Does not meet the VIP withdrawal') && menu_key.value.includes('Please upgrade to VIP')){
            menuNotice.value['提现vip等级不够'] = i18n.t('wallt.withdraw.不满足VIP提款金额要求请升级VIP');
            menu_key.value = '提现vip等级不够';
        };

        // 提款金额不能大于
        if(menu_key.value.includes('提款金额不能大于')){
            let values_arr = matchString(real_desc.value);  // 获取动态值
            menuNotice.value['提款金额不能大于'] = i18n.t('notice.提款金额不能大于', { amount: goldFormat(values_arr[0]) });
            menu_key.value = '提款金额不能大于';
        };

        // 今日提款额超出上限
        if(menu_key.value.includes('今日提款额超出上限')){
            menuNotice.value['今日提款额超出上限'] = i18n.t('notice.今日提款额超出上限');
            menu_key.value = '今日提款额超出上限';
        };

        // 今日提款次数超出上限
        if(menu_key.value.includes('今日提款次数超出上限')){
            menuNotice.value['今日提款次数超出上限'] = i18n.t('notice.今日提款次数超出上限');
            menu_key.value = '今日提款次数超出上限';
        };

        // 巴西提现临时加状态
        if(menu_key.value.includes('提款申请成功')){
            store.commit("temp/setWithdrawTemp", true);
        }

        is_show.value = true;
    };

}



</script>

<style lang="less" scoped>
.wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .block {
        width: 340px;
        position: relative;
        top: 15px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        background: #31314B;
        border-radius: 10px;
        overflow: hidden;
        .header{
            width: 100%;
            height: 45px;
            background: #4A4A70;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .title{
                font-size: 16px;
                color: #FFF;
            }
            .close{
                position: absolute;
                top: 10px;
                right: 10px;
            }
        }
        .content{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0px 20px;
            padding-top: 30px;
            padding-bottom: 100px;
            box-sizing: border-box;
            position: relative;
            .desc{
                color: #fff;
            }
            .button{
                width: 200px;
                height: 36px;
                border-radius: 8px;
                font-size: 14px;
                background: linear-gradient(to top right, #6630F6, #8423FF);
                color: #FFF;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: absolute;
                bottom: 20px;
            }
        }
    }
}
</style>