export default {
  'home.minbanner.activity1.标题': '新人特惠',
  'home.minbanner.activity1.描述': '至高',
  'home.minbanner.activity1.全部': '赠送',
  'home.minbanner.activity3.标题': '每日签到',
  'home.minbanner.activity3.描述': '抽多少送多少',
  'home.minbanner.activity4.标题': '成为VIP领大奖',

  'home.game.lobby': '全部',
  'home.game.hot': '热门',
  'home.game.slots': 'Slots',
  'home.game.BY': '捕鱼',
  'home.game.casino': 'Casino',
  'home.game.SX': '视讯',
  'home.game.TY': '体育',
  'home.game.NEW': '最新',
  'home.game.AG': 'AG',
  'home.game.BBIN': 'BBIN',
  'home.game.CMD': 'CMD',
  'home.game.CQ9': 'CQ9',
  'home.game.EVO': 'EVO',
  'home.game.FBGAME': 'FBGAME',
  'home.game.JDB': 'JDB',
  'home.game.JILI': 'JILI',
  'home.game.LHDJ': 'LHDJ',
  'home.game.NEXTSPIN': 'NEXTSPIN',
  'home.game.PG': 'PG',
  'home.game.PLAYNGO': 'PLAYNGO',
  'home.game.PLAYTECH': 'PLAYTECH',
  'home.game.PP': 'PP',
  'home.game.TADA': 'TADA',
  'home.game.SW': 'SW',
  'home.game.ZITRO': 'ZITRO',
  'home.game.BG': 'BG',
  'home.game.REDTIGER': 'REDTIGER',
  'home.game.RELAXGAMING': 'RELAX-GAMING',
  'home.game.SA': 'SA',
  'home.game.SABA': 'SABA',
  'home.game.SPRIBECASINO': 'SPRIBE-CASINO',
  'home.game.SUPERSPADE': 'SUPERSPADE',
  'home.game.V8': 'V8',
  'home.game.WM': 'WM',
  'home.game.WX168': 'WX168',
  'home.game.查看全部': '查看全部',

  'home.exitdownpopup.提示': '提示',
  'home.exitdownpopup.内容': '亲爱的玩家，由于网络问题，你的 {coin} 金币，在 {platform} 平台上，暂时没有结算，请耐心等待一些时间。',
  'home.exitdownpopup.联系客服': '联系客服',

  'home.share.加入我们的社区': '加入我们的社区',

  'home.icons.支持的充值平台': '支持的充值平台',
  'home.icons.赞助和博彩责任': '合作方',

  'home.metadesc.赞助和博彩责任': '7WIN.GAME',
  'home.metadesc.描述': `With a player-centric approach, 7win.game is able to satisfy millions of gamblers across the globe. 7win.game has its priority set on its community, ensuring an everlasting and endlessly entertaining gambling experience.Your use of and access to 7win.game signifies that you fully understand and agree to be legally bound by the contents of our Terms of Service and Responsible Gaming Policy`,

  'home.selectsession.门槛': '门槛',
  'home.selectsession.初级场': '初级场',
  'home.selectsession.中级场': '中级场',
  'home.selectsession.高级场': '高级场',
  'home.selectsession.精英场': '精英场',
  'home.selectsession.余额不足': '余额不足',
  'home.selectsession.只有充值金才能玩': '只有充值金才能玩',
  'home.selectsession.充值金币余额不满足当前场次进入门槛': '充值金币余额不满足当前场次进入门槛',
  'home.selectsession.当前场次条件是xx': '当前场次条件是{min}-{max}, 您不符合当前条件！',
  'home.selectsession.当前余额不足': '当前余额不足，请充值',

  'home.entergame.用户余额不足': '用户余额不足',
  'home.entergame.上分失败': '上分失败',
  'home.entergame.三方游戏进入提示': '您当前的存款余额为{balance},可转账到{platform_name}的金额为{coin},注册率{rate}:1',
  'home.entergame.三方游戏不能进入提示': '充值资产才能带入{platform_name}',

  'home.loading.首次加载慢请耐心等待': '游戏首次加载可能需要一段时间。请耐心等待！',
  'home.guide.奖金被锁定下注可解锁': '奖金被锁定！下注可解锁！解锁后可免费提现！',
  'home.guide.下注R$1可解锁R$5': '下注R$1可解锁R$5！下注越多解锁越多！',
  'home.guide.点击按钮可立即领取奖励': '点击按钮可立即领取奖励！领取后可免费提现！',
}