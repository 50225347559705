import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "block" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "content" }
const _hoisted_5 = { class: "desc" }

import { computed, onBeforeMount, onBeforeUnmount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { menuNotice } from './menuType';
import evbus from '@/utils/mitt';
import { useStore } from 'vuex';
import { closeToast } from 'vant';
import { matchString,goldFormat, isBrShowFirstGuide } from "@/utils/function";
import { IMGetInfoActivite } from '@/utils/im';
import { useRoute } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'Notice',
  setup(__props) {

const i18n = useI18n();
const store = useStore();
const route = useRoute();

const is_show = ref(false);
// 通知类型
const menu_key = ref('');
// 通知内容
const real_desc = ref('');
const show_desc = computed(() => {
    return menuNotice.value[menu_key.value] ? menuNotice.value[menu_key.value] : menu_key.value;
});

const is_show_home_guide = computed(() => {
    if (route.name === 'home') {
        return isBrShowFirstGuide();
    }
    return false;
});

onBeforeMount(()=>{
    evbus.on('websoket_message',onWebsoketMessage);
});

onBeforeUnmount(()=>{
    evbus.off('websoket_message',onWebsoketMessage);
});



// 处理seokt消息
const onWebsoketMessage = (message:any) => {
    // 被挤下线
    if(message.cmd_name === 'Pmd.ReconnectKickoutLoginUserPmd_S'){
        closeToast();

        if(message.desc.includes('The system has detected that your account may be logged in on multiple devices') && message.desc.includes('please change your account password promptly')){
            menu_key.value = '账号被其它设备登录';
        }else{
            menu_key.value = '账号在其它地方被登录';
        };

        is_show.value = true;
    };

    // 充值次数+1
    if(message.do === 'Cmd.UserInfoGetLobbyCmd_S' && message.data?.rechargeChips){
        const userinfo = JSON.parse(JSON.stringify(store.state.user.userinfo));
        userinfo.rechargeTimes ? userinfo.rechargeTimes++ : userinfo.rechargeTimes = 1;
        store.commit('user/setUserInfo',userinfo);
        IMGetInfoActivite();
    };

    // 通知提示
    if(message.do === 'Cmd.SysMessageMahjongLobbyCmd_S' || message.do === 'Cmd.SysMessageCmd_S'){
        closeToast();

        menu_key.value = message.data.desc || message.data.msg;
        real_desc.value = message.data.desc || message.data.msg;

        // 当前提现银行正在维护
        if(menu_key.value.includes('The current cash withdrawal bank:') && menu_key.value.includes('please change your bank card and try again!')){
            let values_arr = matchString(real_desc.value);  // 获取动态值
            // 如果有menuNotice有对应提示翻译，menuNotice.value展示文案 = 翻译+动态值
            menuNotice.value['正在维护'] = i18n.t('notice.正在维护', { channel: values_arr[0] });
            // menu_key.value = 翻译KEY
            menu_key.value = '正在维护';
        };

        // 领取新版注册送金币
        if(menu_key.value.includes('Successfully received') && menu_key.value.includes('gold coins')){
            let values_arr = matchString(real_desc.value);  // 获取动态值
            menuNotice.value['领取新版注册送金币'] = i18n.t('notice.领取新版注册送金币', { amount: goldFormat(values_arr[0]) });
            menu_key.value = '领取新版注册送金币';
        };

        // 领取新版注册送金币 最后一次领取
        if(menu_key.value.includes('You have successfully received') && menu_key.value.includes('the newbie special event will be offline soon. Thank you for your support!')){
            let values_arr = matchString(real_desc.value);  // 获取动态值
            menuNotice.value['领取新版注册送金币最后一次领取'] = i18n.t('notice.领取新版注册送金币', { amount: goldFormat(values_arr[0]) });
            menu_key.value = '领取新版注册送金币最后一次领取';
        };

        // 领取新版注册送金币 最后一次领取2
        if(menu_key.value.includes('You’ve successfully claimed') && menu_key.value.includes('Your recharge rebate coins have been fully claimed. Please wait for the next event!')){
            let values_arr = matchString(real_desc.value);  // 获取动态值
            menuNotice.value['领取新版注册送金币最后一次领取2'] = i18n.t('notice.领取新版注册送金币最后一次领取2', { amount: goldFormat(values_arr[0]) });
            menu_key.value = '领取新版注册送金币最后一次领取2';
        };

        // 提现vip等级不够
        if(menu_key.value.includes('Does not meet the VIP withdrawal') && menu_key.value.includes('Please upgrade to VIP')){
            menuNotice.value['提现vip等级不够'] = i18n.t('wallt.withdraw.不满足VIP提款金额要求请升级VIP');
            menu_key.value = '提现vip等级不够';
        };

        // 提款金额不能大于
        if(menu_key.value.includes('提款金额不能大于')){
            let values_arr = matchString(real_desc.value);  // 获取动态值
            menuNotice.value['提款金额不能大于'] = i18n.t('notice.提款金额不能大于', { amount: goldFormat(values_arr[0]) });
            menu_key.value = '提款金额不能大于';
        };

        // 今日提款额超出上限
        if(menu_key.value.includes('今日提款额超出上限')){
            menuNotice.value['今日提款额超出上限'] = i18n.t('notice.今日提款额超出上限');
            menu_key.value = '今日提款额超出上限';
        };

        // 今日提款次数超出上限
        if(menu_key.value.includes('今日提款次数超出上限')){
            menuNotice.value['今日提款次数超出上限'] = i18n.t('notice.今日提款次数超出上限');
            menu_key.value = '今日提款次数超出上限';
        };

        // 巴西提现临时加状态
        if(menu_key.value.includes('提款申请成功')){
            store.commit("temp/setWithdrawTemp", true);
        }

        is_show.value = true;
    };

}




return (_ctx: any,_cache: any) => {
  const _component_van_icon = _resolveComponent("van-icon")!
  const _component_van_overlay = _resolveComponent("van-overlay")!

  return (_openBlock(), _createBlock(_component_van_overlay, {
    show: is_show.value && !is_show_home_guide.value,
    "z-index": "1000"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "wrapper",
        onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(i18n).t('public.通知')), 1),
            _createElementVNode("div", {
              class: "close",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (is_show.value = false))
            }, [
              _createVNode(_component_van_icon, {
                name: "cross",
                color: "#8C86B2",
                size: "24"
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(show_desc.value), 1),
            _createElementVNode("div", {
              class: "button",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (is_show.value = false))
            }, _toDisplayString(_unref(i18n).t('public.确认')), 1)
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["show"]))
}
}

})