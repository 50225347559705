import { getActiviteData } from '@/utils/function';
import { computed } from 'vue';
import i18n from '@/locale/index';



const { t } = i18n.global;

export enum activity_config {
    FirstShop = 10001,              //首充活动
    Shop1 = 11001,                  //充值送一
    BindCard = 12001,               //绑定银号卡
    NextDayLogin = 12002,           //次日登陆
    EveryDaySign = 12003,           //每日登陆签到
    WeeklyChips = 12004,            //VIP每周送
    RegisterChips = 12005,          //注册送
    AddFriends = 12006,             //加好友送zalo
    BreakThrough = 12007,           //闯关活动
    AgentChips = 13001,             //加好友送
    Fish = 13005,                   //捕鱼活动
    Luck = 13004,                   //抽奖活动
    Slot = 13006,                   //slot活动
    Chess = 13007,                  //棋牌黄金周
    WorldCup = 13008,               //世界杯
    WorldCupAgent = 13009,          //世界杯代理
    WorldCupRecharge = 13010,       //世界杯充值
    LuckySign = 13011,              //世界杯充值
    ShiYi = 11002,                  //双11
    NewYearRedBag = 13012,          //红包
    FreeCoinsLuckySign = 90001,     //免费金币
    FreeRechargeCoins = 90002,      //免费充值金币
    JackpotCoins = 90003,           //Jackpot金币

    NewYear = 13013,                //抽卡活动
    NewcomerSpecial = 13014,        //新的注册送活动

    //其他类型活动
    OldUserReturn = 20001,          //老用户回归
    UpdateAPK = 20002,              //整包更新
};

export const activity_list: any = computed(() => {
    return [
        {   // 注册送
            imgUrl: new URL('@/assets/image/activity/banner_04.png', import.meta.url).href,
            activity_id: activity_config.NewcomerSpecial,
            activity_info: getActiviteData(activity_config.NewcomerSpecial),
            activity_time: '2024/10/01 - 2025/10/01'
        },
        {   // 代理
            imgUrl: new URL('@/assets/image/activity/banner_01.png', import.meta.url).href,
            activity_id: activity_config.AgentChips,
            activity_info: getActiviteData(activity_config.AgentChips),
            activity_time: '2024/10/01 - 2025/10/01'
        },
        {   // 每日签到
            imgUrl: new URL('@/assets/image//activity/banner_02.png', import.meta.url).href,
            activity_id: activity_config.LuckySign,
            activity_info: getActiviteData(activity_config.LuckySign),
            activity_time: '2024/10/01 - 2025/10/01'
        },
        {   // VIP
            imgUrl: new URL('@/assets/image//activity/banner_03.png', import.meta.url).href,
            activity_id: activity_config.WeeklyChips,
            activity_info: getActiviteData(activity_config.WeeklyChips),
            activity_time: '2024/10/01 - 2025/10/01'
        },
    ]
});

export const color_map: any = {
    10001: "#FF4081", // 首充活动
    11001: "#4CAF50", // 充值送一
    12001: "#2196F3", // 绑定银号卡
    12002: "#E91E63", // 次日登陆
    12003: "#FFEB3B", // 每日登陆签到
    12004: "#9C27B0", // VIP 每周送
    12005: "#673AB7", // 注册送
    12006: "#795548", // 加好友送 zalo
    12007: "#8BC34A", // 闯关活动
    13001: "#F44336", // 加好友送 (不同颜色)
    13005: "#00BCD4", // 捕鱼活动 (不同颜色)
    13004: "#607D8B", // 抽奖活动 (不同颜色)
    13006: "#D81B60", //slot 活动 (不同颜色)
    13007: "#FFC107", // 棋牌黄金周 (不同颜色)
    13008: "#FF5722", // 世界杯 (不同颜色)
    13009: "#9E9E9E", // 世界杯代理 (不同颜色)
    13010: "#CDDC39", // 世界杯充值 (不同颜色)
    13011: "#E64A19", // 世界杯充值 (不同颜色)
    11002: "#FFAB40", // 双 11 (不同颜色)
    13012: "#009688", // 红包 (不同颜色)
    90001: "#3F51B5", // 免费金币 (不同颜色)
    90002: "#FF6E40", // 免费充值金币 (不同颜色)
    90003: "#FFC400", // Jackpot 金币 (不同颜色)
    13013: "#B71C1C", // 抽卡活动 (不同颜色)
    13014: "#5D4037", // 新的注册送活动 (不同颜色)
    20001: "#880E4F", // 老用户回归 (不同颜色)
    20002: "#00E676", // 整包更新 (不同颜色)
    13002: "#4527A0", // 代理佣金 (不同颜色)
    13003: "#FFAB91", //vip 返利金币 (不同颜色)

    'chips': "#FFA726", // Chips 活动
    'rechargeChips': "#00BFA5", // Recharge Chips 活动
}

export const name_map: any = {
    10001: t('activity.coinname.10001'),                 //首充活动
    11001: t('activity.coinname.11001'),                 //充值送一
    12001: t('activity.coinname.12001'),                //绑定银号卡
    12002: t('activity.coinname.12002'),                 //次日登陆
    12003: t('activity.coinname.12003'),                 //每日登陆签到
    12004: t('activity.coinname.12004'),               //VIP每周送
    12005: t('activity.coinname.12005'),              //注册送
    12006: t('activity.coinname.12006'),                //加好友送zalo
    12007: t('activity.coinname.12007'),              //闯关活动
    13001: t('activity.coinname.13001'),              //加好友送
    13005: t('activity.coinname.13005'),                 //捕鱼活动
    13004: t('activity.coinname.13004'),              //抽奖活动
    13006: t('activity.coinname.13006'),                 //slot活动
    13007: t('activity.coinname.13007'),                 //棋牌黄金周
    13008: t('activity.coinname.13008'),                 //世界杯
    13009: t('activity.coinname.13009'),                 //世界杯代理
    13010: t('activity.coinname.13010'),                 //世界杯充值
    13011: t('activity.coinname.13011'),              //世界杯充值
    11002: t('activity.coinname.11002'),                 //双11
    13012: t('activity.coinname.13012'),              //红包
    90001: t('activity.coinname.90001'),              //免费金币
    90002: t('activity.coinname.90002'),                    //免费充值金币
    90003: t('activity.coinname.90003'),                    //Jackpot金币
    13013: t('activity.coinname.13013'),                    //抽卡活动
    13014: t('activity.coinname.13014'),                    //新的注册送活动
    20001: t('activity.coinname.20001'),                //老用户回归
    20002: t('activity.coinname.20002'),                //整包更新
    13002: t('activity.coinname.13002'),                //代理佣金
    13003: t('activity.coinname.13003'),                //vip返利金币

    'chips': t('activity.coinname.chips'),
    'rechargeChips': t('activity.coinname.rechargeChips'),
}