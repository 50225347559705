export default {
  'wallt.充值': 'Nạp tiền',
  'wallt.提现': 'Rút tiền',

  'wallt.recharge.充值方式': 'Phương thức nạp',
  'wallt.recharge.充值金额': 'Số tiền nạp',
  'wallt.recharge.确认充值': 'Xác nhận nạp tiền',
  'wallt.recharge.请输入银行账户': 'Nhập tài khoản ngân hàng',
  'wallt.recharge.请输入开户名': 'Nhập tên',
  'wallt.recharge.请输入手机号': 'Nhập số điện thoại',
  'wallt.recharge.输入您的实际付款Momo帐户以完成付款': 'Hãy nhập tài khoản Momo thanh toán thực tế để hoàn tất thanh toán',
  'wallt.recharge.请准确填写您的支付账户,填写错误可能会导致转账资金的损失': 'Vui lòng điền chính xác tài khoản thanh toán của bạn. Nếu điền sai có thể bị mất tiền.',
  'wallt.recharge.填写正确的银行相关信息可提高充值成功率': 'Điền chính xác thông tin liên quan đến ngân hàng để cải thiện tỷ lệ nạp tiền thành công',
  'wallt.recharge.汇率': 'Tỷ giá',
  'wallt.recharge.参与活动': 'Tham gia sự kiện',
  'wallt.recharge.注册送': 'Ưu đãi tân thủ',
  'wallt.recharge.签到': 'Điểm danh',
  'wallt.recharge.当前参与活动': 'Hiện đang tham gia hoạt động',
  'wallt.recharge.充值额度': 'Số tiền nạp',
  'wallt.recharge.赠送额度': 'Số tiền tặng',
  'wallt.recharge.您还不能参加此活动': 'Bạn hiện không thể tham gia sự kiện này',
  'wallt.recharge.下订单失败': 'Đặt hàng thất bại',
  'wallt.recharge.下订单失败，请尝试大额支付或其他支付通道!': 'Đặt hàng thất bại, vui lòng thử thanh toán số tiền lớn hoặc các kênh thanh toán khác!',
  'wallt.recharge.当前充值渠道正在维护': 'Kênh nạp hiện tại đang bảo trì',
  'wallt.recharge.当前银行正在维护': 'Hiện tại ngân hàng đang bảo trì',
  'wallt.recharge.您当前不能参与活动确认要充值吗': 'Bạn hiện không thể tham gia sự kiện này. Bạn có xác nhận nạp tiền không?',
  'wallt.recharge.选择银行卡': 'Chọn thẻ ngân hàng',
  'wallt.recharge.充值': 'Chọn số tiền',
  'wallt.recharge.手续费': 'Phí xử lý',
  'wallt.recharge.需要扣除手续费': 'phương thức nạp tiền này cần phí xử lý',
  'wallt.recharge.实际到账': 'Số tiền thực nhận',
  'wallt.recharge.你不能参与任何活动,还要遭扣除手续费': 'Phương thức nạp tiền này không hỗ trợ tham gia bất kỳ hoạt động nào! Nếu mệnh giá thẻ cào cao hơn lệnh nạp bạn đã tạo, phần chênh lệch người chơi tự chịu tốn thất!',
  'wallt.recharge.确认': 'Xác nhận',
  'wallt.recharge.二维码描述': 'Nếu không thể sử dụng mã QR, vui lòng sao chép thông tin bên dưới để chuyển tiền',
  'wallt.recharge.银行名称': 'Tên ngân hàng',
  'wallt.recharge.账户': 'Số tài khoản',
  'wallt.recharge.账户名称': 'Tên tài khoản',
  'wallt.recharge.转账金额': 'Số tiền chuyển',
  'wallt.recharge.复制': 'Sao chép',
  'wallt.recharge.注意': 'Lưu ý',
  'wallt.recharge.请充值相应金额的USDT!不正确的充值金额将导致游戏币无法充值!': 'Hãy nạp số tiền USDT tương ứng! Số tiền nạp không chính xác sẽ dẫn đến không thể nạp tiền thành công!',
  'wallt.recharge.您有一笔订单未支付': 'Bạn còn có 1 lệnh nạp {amount}USTD chưa thanh toán,hãy thanh toán đã!',
  'wallt.recharge.规则1': '1.Vui lòng nhập chính xác nội dung và số tiền yêu từ hệ thống. Thông tin không chính xác sẽ không thể tự động lên điểm.',
  'wallt.recharge.规则2': '2. Nếu không thể sử dụng mã QR Code, vui lòng sao chép tài khoản thanh toán.',
  'wallt.recharge.规则3': '3.Khi chuyển khoản không làm mới trình duyệt.',
  'wallt.recharge.认证': 'PCI DSS certification',
  'wallt.recharge.认证描述': 'Đạt chứng nhận bảo mật dữ liệu tài chính có thẳm quyền và tuân thủ các tiêu chuẩn bảo mật quốc tế',
  'wallt.rechargechannel.网关': 'Chuyển tại quầy',
  'wallt.rechargechannel.银行转账': 'Chuyển khoản',
  'wallt.rechargechannel.ZaloPay': 'ZaloPay',
  'wallt.rechargechannel.VIETQR': 'VIETQR',
  'wallt.rechargechannel.MomoPay': 'MomoPay',
  'wallt.rechargechannel.Nạp thẻ cào': 'Nạp thẻ cào',
  'wallt.rechargechannel.Chỉ định QR BANK': 'Chị định QR BANK',
  'wallt.rechargechannel.VTPay': 'VTPay',
  'wallt.rechargechannel.银行扫码': 'Quét mã QR',

  'wallt.withdraw.绑定银行卡': 'Ràng buộc thẻ ngân hàng',
  'wallt.withdraw.更换银行卡': 'Thay đổi thẻ ngân hàng',
  'wallt.withdraw.提现信息': 'Thông tin rút tiền',
  'wallt.withdraw.最高可提现金额': 'Số tiền rút tối đa',
  'wallt.withdraw.当前选择金额': 'Số tiền hiện được chọn',
  'wallt.withdraw.服务费': 'Phí xử lý',
  'wallt.withdraw.收到金额': 'Nhận được',
  'wallt.withdraw.手续费': 'Phí xử lý',
  'wallt.withdraw.当前提现金额大于最大可提现金额': 'Số tiền rút hiện tại lớn hơn số tiền rút tối đa',
  'wallt.withdraw.提现金额': 'Số tiền rút',
  'wallt.withdraw.当前提现渠道正在维护': 'Kênh rút tiền hiện tại đang bảo trì',
  'wallt.withdraw.确定': 'Xác nhận',
  'wallt.withdraw.当前金额正在维护': 'Số tiền hiện tại đang bảo trì',
  'wallt.withdraw.当前提现金额不符合最大可提现金额': 'Số tiền rút tiền hiện tại không đáp ứng số tiền rút tối đa',
  'wallt.withdraw.不满足VIP提款金额要求请升级VIP': 'Không đáp ứng yêu cầu số tiền rút của VIP! Vui lòng nâng cấp lên VIP!',
  'wallt.withdraw.不满足vip1提现金额要求请充值': 'Không đáp ứng yêu cầu rút tiền VIP hiện tại! Nạp R$150 để trở thành VIP2 và rút toàn bộ số tiền!',
  'wallt.withdraw.您的余额已满足提现要求可以立即提现': 'Số dư của bạn đã đạt yêu cầu rút tiền! Trở thành VIP để rút tiền ngay lập tức!',
  'wallt.withdraw.您的余额已满足提现要求可以立即提现3': 'Nạp R$100 để trở thành VIP2! VIP2 có thể rút toàn bộ số tiền ngay lập tức!',
  'wallt.withdraw.您的余额已满足提现要求可以立即提现4': 'Bạn có thể rút ngay R$10! Hãy rút tiền ngay!',
  'wallt.withdraw.您的余额已满足提现要求可以立即提现5': 'Nạp thêm R$50 để rút toàn bộ số tiền',
  'wallt.withdraw.成为VIP后可以立即提现': 'Sau khi trở thành VIP, bạn có thể rút tiền ngay lập tức',
  'wallt.withdraw.请选择银行': 'Chọn ngân hàng',
  'wallt.withdraw.请输入您的姓名': 'Nhập tên của bạn',
  'wallt.withdraw.请输入您的银行卡号': 'Nhập số thẻ ngân hàng của bạn',
  'wallt.withdraw.请输入您的钱包地址': 'Nhập địa chỉ Ví',
  'wallt.withdraw.请输入您的电子邮件': 'Nhập email của bạn',
  'wallt.withdraw.请输入提款密码': 'Nhập mật khẩu rút tiền',
  'wallt.withdraw.请确认提款密码': 'Xác nhận mật khẩu rút tiền',
  'wallt.withdraw.请检查表单是否填写完整': 'Kiểm tra xem biểu mẫu đã được điền đầy đủ chưa',
  'wallt.withdraw.请输入正确的邮箱': 'Nhập email chính xác',
  'wallt.withdraw.密码不能少于6位': 'Mật khẩu không được ít hơn 6 ký tự',
  'wallt.withdraw.两次密码输入不一致': 'Hai lần nhập mật khẩu không nhất quán',
  'wallt.withdraw.提款密码': 'Mật khẩu rút tiền',
  'wallt.withdraw.忘记密码': 'Quên mật khẩu',
  'wallt.withdraw.请输入密码': 'Nhập mật khẩu',
  'wallt.withdraw.更换银行卡需要验证交易密码': 'Đổi thẻ ngân hàng cần xác thực mật khẩu giao dịch',
  'wallt.withdraw.密码错误': 'Sai mật khẩu',
  'wallt.withdraw.服务器内部错误': 'Lỗi nội bộ máy chủ',
  'wallt.withdraw.短信验证': 'Xác minh qua tin nhắn',
  'wallt.withdraw.手机号': 'Số điện thoại',
  'wallt.withdraw.请输入验证码': 'Nhập mã xác minh',
  'wallt.withdraw.发送': 'Gửi',
  'wallt.withdraw.验证码发送成功': 'Gửi CAPTCHA thành công',
  'wallt.withdraw.验证码错误': 'Lỗi CAPTCHA',
  'wallt.withdraw.发送验证码': 'Gửi CAPTCHA',
  'wallt.withdraw.br.绑定提款账号': 'Liên kết tài khoản rút tiền',
  'wallt.withdraw.br.请选择取款方式': 'Vui lòng chọn phương thức rút tiền',
  'wallt.withdraw.br.请输入用户姓名': 'Vui lòng nhập tên người dùng',
  'wallt.withdraw.br.请输入取款账号': 'Vui lòng nhập tài khoản rút tiền',
  'wallt.withdraw.br.请输入邮箱': 'Vui lòng nhập email',
  'wallt.withdraw.br.请输入手机号': 'Vui lòng nhập số điện thoại',
  'wallt.withdraw.br.请输入正确的CPF': 'Vui lòng nhập CPF chính xác',

  'wallt.withdraw.可提现佣金': 'Hoa hồng có thể rút',
  'wallt.withdraw.代理佣金提现': 'Rút tiền hoa hồng đại lý',
  'wallt.withdraw.当前界面不可提现代理佣金部分': 'Phần hoa hồng đại lý không thể rút ở giao diện hiện tại! Để rút hoa hồng đại lý, vui lòng truy cập trang đại lý!',
  'wallt.withdraw.每个人都可以参与的独立提款系统': 'Hệ thống rút tiền độc lập mà ai cũng có thể tham gia',

  'wallt.transrecord.交易记录': 'Lịch sử giao dịch',
  'wallt.transrecord.充值': 'Nạp tiền',
  'wallt.transrecord.提现': 'Rút tiền',
  'wallt.transrecord.没有数据': 'Không có dữ liệu',
  'wallt.transrecord.没有更多数据了': 'Không có thêm dữ liệu ',
  'wallt.transrecord.支付中': 'Đang thanh toán',
  'wallt.transrecord.成功': 'Thành công',
  'wallt.transrecord.失败': 'Thất bại',
  'wallt.transrecord.处理中': 'Đang xử lý',
  'wallt.transrecord.类型': 'Loại',
  'wallt.transrecord.金额': 'Số tiền',
  'wallt.transrecord.渠道': 'Kênh',
  'wallt.transrecord.状态': 'Trạng thái',


}