export default {
  'register_gift.popup.标题': 'BONUS BESAR UNTUK SETIAP DEPOSIT',
  'register_gift.popup.描述': 'HINGGA',
  'register_gift.popup.详情': 'Detail',
  'register_gift.popup.赠送金币解锁后无需流水即可提现': 'Penarikan Koin yang Dilepaskan Tanpa Persyaratan Taruhan',
  'register_gift.popup.赠送': 'Bonus',
  'register_gift.popup.立即充值': 'Deposit Sekarang',
  'register_gift.popup.首次充值': 'Deposit Pertama',
  'register_gift.popup.二次充值': 'Deposit Kedua',
  'register_gift.popup.三次充值': 'Deposit Ketiga',
  'register_gift.popup.四次充值': 'Deposit Keempat',
  'register_gift.popup.活动刷新倒计时': 'Hitung mundur pembaruan acara',

  'register_gift.标题': 'BONUS BESAR UNTUK SETIAP DEPOSIT',
  'register_gift.首次充值': 'Deposit Pertama',
  'register_gift.送全部注册金币': 'Bonus: {rate} Saldo Bonus Pendatang Baru',
  'register_gift.赠送金额': 'Bonus',
  'register_gift.玩法': 'Cara Bermain',
  'register_gift.玩法规则1': '1. Deposit jumlah berapa saja untuk menerima {rate}% dari saldo Bonus Pendatang Baru',
  'register_gift.玩法规则1.1': '1. Terima {rate}% dari jumlah deposit setelah berhasil melakukan deposit',
  'register_gift.玩法规则2': '2. Setelah deposit, bonus tidak dapat digunakan sampai dilepaskan dengan Taruhan Deposit',
  'register_gift.玩法规则3': '3. Cara Melepaskan: Taruhan Deposit',
  'register_gift.玩法规则4': '4. Penarikan Koin yang Dilepaskan Tanpa Persyaratan Taruhan',
  'register_gift.举例': 'Contoh',
  'register_gift.赠送金币为': 'Bonus',
  'register_gift.当前充值金流水': 'Taruhan Deposit',
  'register_gift.领取解锁金币后将重新计算': '(Hitung Ulang Setelah Dilepaskan)',
  'register_gift.可解锁金币': 'Klaim',
  'register_gift.领取后赠送金币余额': 'Saldo Bonus Setelah Dilepaskan',
  'register_gift.立即充值': 'Deposit Sekarang',
  'register_gift.最低充值要求': 'Persyaratan isi ulang minimum',
  'register_gift.任意金额': 'Jumlah berapa pun',

  'register_gift.锁定金币总余额': 'Koin yang Terkunci:',
  'register_gift.充值金流水': 'Taruhan Deposit',
  'register_gift.锁定金币': 'Terkunci',
  'register_gift.速率': 'Kecepatan Pelepasan',
  'register_gift.已领取金币': 'Telah Diklaim',
  'register_gift.可领取金币': 'Dapat Diklaim',
  'register_gift.领取金币': 'Klaim',
  'register_gift.首充赠送金币': 'Bonus Deposit Pertama',
  'register_gift.二充赠送金币': 'Bonus Deposit Kedua',
  'register_gift.三充赠送金币': 'Bonus Deposit Ketiga',
  'register_gift.四充赠送金币': 'Bonus Deposit Keempat',

  'register_gift.第x次充值': 'Deposit ke-{num}',
  'register_gift.送充值金额': 'Bonus: Jumlah Deposit',
  'register_gift.充值金额': 'Jumlah Deposit',

  'register_gift.恭喜你已获得所有新手奖励': 'Anda telah menerima semua hadiah',
  'register_gift.如何玩': 'Cara Bermain',
  'register_gift.充值金币流水可解锁赠送金币': 'Bonus tidak dapat digunakan sampai dilepaskan dengan Taruhan Deposit',
  'register_gift.解锁金币计算公式': 'Cara Membuka Koin',
  'register_gift.充值金流水*当前解锁金币速率': 'Taruhan Deposit×Kecepatan Pelepasan',
  'register_gift.解锁速率': 'Kecepatan Pelepasan',
  'register_gift.解锁优先级': 'Prioritas Pelepasan',
  'register_gift.查看规则': 'Lihat Aturan',
  'register_gift.充值金流水越高,解锁金币越多': 'Semakin Tinggi Taruhan Deposit, Semakin Banyak Koin yang Dilepaskan!',

  'register_gift.header.锁定金额': 'Terkunci',
  'register_gift.header.可解锁': 'Klaim',
  'register_gift.header.领取': 'Klaim',
}
