import instance from "@/utils/request";
import { getMiddlewareUrl } from "@/utils/function";

// 获取游戏列表
export function getGameList(value: any) {
    instance.defaults.baseURL = getMiddlewareUrl();
    return instance.get('/cs/lobby_cp/list-game-set',{
        params: value
    });
}

// 获取未登录时候的游戏列表
export function getNotLoginGameList(value: any) {
    instance.defaults.baseURL = getMiddlewareUrl();
    return instance.get('/cs/lobby_cp/list-game-classify',{
        params: value
    });
}

// 登录游戏
export function loginGame(value: any) {
    instance.defaults.baseURL = getMiddlewareUrl();
    return instance.post('/cs/game/login',value);
}

// 退出游戏
export function exitGame(value: any) {
    instance.defaults.baseURL = getMiddlewareUrl();
    return instance.post('/cs/game/logout',value);
}

// 用户上分
export function userTopUp(value: any) {
    instance.defaults.baseURL = getMiddlewareUrl();
    return instance.post('/cs/external/login',value);
}

// 用户下分
export function userTopDown(value: any) {
    instance.defaults.baseURL = getMiddlewareUrl();
    return instance.post('/cs/external/offline',value);
}