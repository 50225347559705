import createPersistedState from 'vuex-persistedstate';
import { createStore } from 'vuex'

import setting from './modules/setting'; // 设置模块
import user from './modules/user';
import game from './modules/game';
import temp from './modules/temp';


export default createStore({
  modules: {
    setting,
    user,
    game,
    temp
  },
  plugins: [
    createPersistedState({
      storage: window.localStorage
    })
  ]
})
