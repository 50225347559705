export default {
  'wallt.充值': 'Recharge',
  'wallt.提现': 'Withdraw',

  'wallt.recharge.充值方式': 'Recharge Method',
  'wallt.recharge.充值金额': 'Recharge Amount',
  'wallt.recharge.确认充值': 'Confirm Recharge',
  'wallt.recharge.请输入银行账户': 'Please enter bank account',
  'wallt.recharge.请输入开户名': "Please enter account holder's name",
  'wallt.recharge.请输入手机号': 'Please enter phone number',
  'wallt.recharge.输入您的实际付款Momo帐户以完成付款': 'Enter your actual account to complete the payment',
  'wallt.recharge.请准确填写您的支付账户,填写错误可能会导致转账资金的损失': 'Please fill in your payment account accurately. Incorrect information may result in loss of transferred funds',
  'wallt.recharge.填写正确的银行相关信息可提高充值成功率': 'Filling in the correct bank information can increase the success rate of the recharge',
  'wallt.recharge.汇率': 'Exchange rate',
  'wallt.recharge.参与活动': 'Participate in Activity',
  'wallt.recharge.注册送': 'Register Bonus',
  'wallt.recharge.签到': 'Sign-In',
  'wallt.recharge.当前参与活动': 'Current Participating Activity',
  'wallt.recharge.充值额度': 'Recharge Amount',
  'wallt.recharge.赠送额度': 'Bonus Amount',
  'wallt.recharge.您还不能参加此活动': 'You are not eligible to participate in this event yet.',
  'wallt.recharge.下订单失败': 'Order Failed',
  'wallt.recharge.下订单失败，请尝试大额支付或其他支付通道!': 'Order Failed, Please Try a Another Amount or Payment Channel!',
  'wallt.recharge.当前充值渠道正在维护': 'The Current Recharge Channel is Under Maintenance',
  'wallt.recharge.当前银行正在维护': 'he Current Bank is Under Maintenance',
  'wallt.recharge.您当前不能参与活动确认要充值吗': 'You Are Not Eligible for the Event, Are You Sure You Want to Recharge?',
  'wallt.recharge.选择银行卡': 'Select Bank Card',
  'wallt.recharge.充值': 'Select Amount',
  'wallt.recharge.手续费': 'Fee',
  'wallt.recharge.需要扣除手续费': 'This Recharge Method Requires a Fee',
  'wallt.recharge.实际到账': 'Actual Amount Received',
  'wallt.recharge.你不能参与任何活动,还需要扣除手续费': 'This recharge method does not support participation in any activity! If the value of the scratch card is higher than the recharge order you created, the player will need to bear the difference in loss!',
  'wallt.recharge.确认': 'Confirm',
  'wallt.recharge.二维码描述': 'If QR code cannot be used, please copy the details below for transfer',
  'wallt.recharge.银行名称': 'Bank Name',
  'wallt.recharge.账户': 'Account Number',
  'wallt.recharge.账户名称': 'Account Name',
  'wallt.recharge.转账金额': 'Transfer Amount',
  'wallt.recharge.复制': 'Copy',
  'wallt.recharge.注意': 'Notice',
  'wallt.recharge.请充值相应金额的USDT!不正确的充值金额将导致游戏币无法充值!': 'Please recharge the corresponding amount of USDT! Incorrect recharge amounts will result in the failure to recharge!',
  'wallt.recharge.您有一笔订单未支付': 'You have an unpaid order of {amount} USDT, please complete the payment first!',
  'wallt.recharge.规则1': '1. Please enter the required information and amount correctly as per the system instructions. If the information is incorrect, the credits cannot be automatically added.',
  'wallt.recharge.规则2': '2. If QR Code is not usable, please copy the payment account.',
  'wallt.recharge.规则3': '3. Do not refresh the browser during the transfer.',
  'wallt.recharge.认证': 'PCI DSS Certification',
  'wallt.recharge.认证描述': 'Compliant with international financial security standards and authorized certification',
  'wallt.rechargechannel.网关': 'Gateway',
  'wallt.rechargechannel.银行转账': 'Bank Transfer',
  'wallt.rechargechannel.ZaloPay': 'ZaloPay',
  'wallt.rechargechannel.VIETQR': 'VIETQR',
  'wallt.rechargechannel.MomoPay': 'MomoPay',
  'wallt.rechargechannel.Nạp thẻ cào': 'Nạp thẻ cào',
  'wallt.rechargechannel.Chỉ định QR BANK': 'Designated QR Bank',
  'wallt.rechargechannel.VTPay': 'VTPay',
  'wallt.rechargechannel.银行扫码': 'Bank QR Code',


  'wallt.withdraw.绑定银行卡': 'Bind Bank Card',
  'wallt.withdraw.更换银行卡': 'Change Bank Card',
  'wallt.withdraw.提现信息': 'Withdrawal Information',
  'wallt.withdraw.最高可提现金额': 'Maximum Withdrawable Amount',
  'wallt.withdraw.当前选择金额': 'Currently Selected Amount',
  'wallt.withdraw.服务费': 'Fee',
  'wallt.withdraw.收到金额': 'Amount Received',
  'wallt.withdraw.手续费': 'Fee',
  'wallt.withdraw.当前提现金额大于最大可提现金额': 'The Current Withdrawal Amount Exceeds the Maximum Withdrawable Amount',
  'wallt.withdraw.提现金额': 'Withdrawal Amount',
  'wallt.withdraw.当前提现渠道正在维护': 'The Current Withdrawal Channel is Under Maintenance',
  'wallt.withdraw.确定': 'Confirm',
  'wallt.withdraw.当前金额正在维护': 'The Current Amount is Under Maintenance',
  'wallt.withdraw.当前提现金额不符合最大可提现金额': 'The Current Withdrawal Amount Does Not Match the Maximum Withdrawable Amount',
  'wallt.withdraw.不满足VIP提款金额要求请升级VIP': 'Does not meet the VIP withdrawal amount requirement! Please upgrade to VIP!',
  'wallt.withdraw.不满足vip1提现金额要求请充值': 'Does not meet the current VIP withdrawal requirements! Recharge R$150 to become VIP2 and withdraw the full amount!',
  'wallt.withdraw.您的余额已满足提现要求可以立即提现': 'Your balance has reached the withdrawal requirement! Become a VIP to withdraw immediately!',
  'wallt.withdraw.您的余额已满足提现要求可以立即提现3': 'Recharge R$100 to become VIP2! VIP2 can immediately withdraw the full amount!',
  'wallt.withdraw.您的余额已满足提现要求可以立即提现4': 'You can immediately withdraw R$10! Please withdraw now!',
  'wallt.withdraw.您的余额已满足提现要求可以立即提现5': 'Recharge another R$50 to withdraw the full amount',
  'wallt.withdraw.成为VIP后可以立即提现': 'After becoming a VIP, you can withdraw immediately',
  'wallt.withdraw.请选择银行': 'Please Select a Bank',
  'wallt.withdraw.请输入您的姓名': 'Please Enter Your Name',
  'wallt.withdraw.请输入您的银行卡号': 'Please Enter Your Bank Card Number',
  'wallt.withdraw.请输入您的钱包地址': 'Please enter your wallet address',
  'wallt.withdraw.请输入您的电子邮件': 'Please Enter Your Email',
  'wallt.withdraw.请输入提款密码': 'Please Enter Withdrawal Password',
  'wallt.withdraw.请确认提款密码': 'Please Confirm Withdrawal Password',
  'wallt.withdraw.请检查表单是否填写完整': 'Please Check if the Form is Complete',
  'wallt.withdraw.请输入正确的邮箱': 'Please Enter a Valid Email',
  'wallt.withdraw.密码不能少于6位': 'Password Must Be at Least 6 Characters',
  'wallt.withdraw.两次密码输入不一致': 'Passwords Do Not Match',
  'wallt.withdraw.提款密码': 'Withdrawal Password',
  'wallt.withdraw.忘记密码': 'Forgot Password',
  'wallt.withdraw.请输入密码': 'Please Enter Password',
  'wallt.withdraw.更换银行卡需要验证交易密码': 'Replacing a Bank Card Requires Verification of Transaction Password',
  'wallt.withdraw.密码错误': 'Incorrect Password',
  'wallt.withdraw.服务器内部错误': 'Internal Server Error',
  'wallt.withdraw.短信验证': 'SMS Verification',
  'wallt.withdraw.手机号': 'Phone Number',
  'wallt.withdraw.请输入验证码': 'Please Enter Verification Code',
  'wallt.withdraw.发送': 'Send',
  'wallt.withdraw.验证码发送成功': 'Verification code sent successfully',
  'wallt.withdraw.验证码错误': 'Verification code error',
  'wallt.withdraw.发送验证码': 'Send verification code',
  'wallt.withdraw.br.绑定提款账号': 'Bind withdrawal account',
  'wallt.withdraw.br.请选择取款方式': 'Please choose a withdrawal method',
  'wallt.withdraw.br.请输入用户姓名': 'Please enter the user’s name',
  'wallt.withdraw.br.请输入取款账号': 'Please enter the withdrawal account',
  'wallt.withdraw.br.请输入邮箱': 'Please enter your email',
  'wallt.withdraw.br.请输入手机号': 'Please enter your phone number',
  'wallt.withdraw.br.请输入正确的CPF': 'Please enter the correct CPF',

  'wallt.withdraw.可提现佣金': 'Withdrawable commission',
  'wallt.withdraw.代理佣金提现': 'Agent commission withdrawal',
  'wallt.withdraw.当前界面不可提现代理佣金部分': 'The agent commission cannot be withdrawn on this page! To withdraw the agent commission, please go to the agent page!',
  'wallt.withdraw.每个人都可以参与的独立提款系统': 'An independent withdrawal system that everyone can participate in',

  'wallt.transrecord.交易记录': 'Transaction Records',
  'wallt.transrecord.充值': 'Recharge',
  'wallt.transrecord.提现': 'Withdraw',
  'wallt.transrecord.没有数据': 'No Data',
  'wallt.transrecord.没有更多数据了': 'No More Data',
  'wallt.transrecord.支付中': 'In Payment',
  'wallt.transrecord.成功': 'Successful',
  'wallt.transrecord.失败': 'Failed',
  'wallt.transrecord.处理中': 'Processing',
  'wallt.transrecord.类型': 'Type',
  'wallt.transrecord.金额': 'Amount',
  'wallt.transrecord.渠道': 'Channel',
  'wallt.transrecord.状态': 'Status',
}
