export default {
  'register_gift.popup.标题': 'โบนัสสุดพิเศษสำหรับทุกการฝากเงิน',
  'register_gift.popup.描述': 'สูงถึง',
  'register_gift.popup.详情': 'รายละเอียด',
  'register_gift.popup.赠送金币解锁后无需流水即可提现': 'ปล่อยถอนเหรียญโดยไม่มีข้อกำหนดในการเดิมพัน!',
  'register_gift.popup.赠送': 'ของรางวัล',
  'register_gift.popup.立即充值': 'เติมเงินเลย',
  'register_gift.popup.首次充值': 'เงินฝากครั้งที่ 1',
  'register_gift.popup.二次充值': 'เงินฝากครั้งที่ 2',
  'register_gift.popup.三次充值': 'เงินฝากครั้งที่ 3',
  'register_gift.popup.四次充值': 'เงินฝากครั้งที่ 4',
  'register_gift.popup.活动刷新倒计时': 'การนับถอยหลังการรีเฟรชกิจกรรม',

  'register_gift.标题': 'โบนัสสุดพิเศษสำหรับทุกการฝากเงิน',
  'register_gift.首次充值': 'เงินฝากครั้งที่ 1',
  'register_gift.送全部注册金币': 'โบนัส: {amount} ยอดคงเหลือของโบนัสสำหรับผู้เล่นใหม่',
  'register_gift.赠送金额': 'โบนัส',
  'register_gift.玩法': 'วิธีการเล่น',
  'register_gift.玩法规则1': '1.ฝากเงินจำนวนใดก็ได้เพื่อรับ {rate}% ของยอดโบนัสผู้มาใหม่',
  'register_gift.玩法规则1.1': '1.เติมเงินเพื่อรับ {rate}% ของจำนวนเงินที่เติม',
  'register_gift.玩法规则2': '2.หลังจากฝากเงินแล้ว โบนัสจะไม่สามารถใช้งานได้จนกว่าจะปล่อยพร้อมกับการเดิมพันฝากเงิน',
  'register_gift.玩法规则3': '3.วิธีปล่อย: การเดิมพันฝากเงิน',
  'register_gift.玩法规则4': '4.การถอนเหรียญที่ปล่อยแล้วโดยไม่มีข้อกำหนดการเดิม',
  'register_gift.举例': 'ตัวอย่าง',
  'register_gift.赠送金币为': 'โบนัส',
  'register_gift.当前充值金流水': 'การเดิมพันเงินฝาก',
  'register_gift.领取解锁金币后将重新计算': 'คำนวณใหม่หลังจากปล่อย',
  'register_gift.可解锁金币': 'เคลม',
  'register_gift.领取后赠送金币余额': 'โบนัสหลังจากปล่อย',
  'register_gift.立即充值': 'เติมเงินเลย',
  'register_gift.最低充值要求': 'ข้อกำหนดการเติมเงินขั้นต่ำ',
  'register_gift.任意金额': '任意金额',

  'register_gift.锁定金币总余额': 'เหรียญที่ยังไม่ปล่อย',
  'register_gift.充值金流水': 'การเดิมพันฝากเงิน',
  'register_gift.锁定金币': 'ล็อค',
  'register_gift.速率': 'อัตราการปล่อย',
  'register_gift.已领取金币': 'เหรียญที่ได้รับ',
  'register_gift.可领取金币': 'พร้อมให้เคลม',
  'register_gift.领取金币': 'รับ',
  'register_gift.首充赠送金币': 'โบนัสเงินฝากครั้งแรก',
  'register_gift.二充赠送金币': 'โบนัสเงินฝากครั้งที่สอง',
  'register_gift.三充赠送金币': 'โบนัสเงินฝากครั้งที่สาม',
  'register_gift.四充赠送金币': 'โบนัสเงินฝากครั้งที่สี่',

  'register_gift.第x次充值': 'การเติมเงินครั้งที่ {num}',
  'register_gift.送充值金额': 'โบนัส: จำนวนเงินฝาก',
  'register_gift.充值金额': 'จำนวนเงินฝาก',

  'register_gift.恭喜你已获得所有新手奖励': 'คุณได้รับรางวัลทั้งหมดแล้ว',
  'register_gift.如何玩': 'วิธีการเล่น',
  'register_gift.充值金币流水可解锁赠送金币': 'โบนัสจะไม่สามารถใช้งานได้จนกว่าจะปล่อยพร้อมกับการเดิมพันฝากเงิน',
  'register_gift.解锁金币计算公式': 'วิธีปลดล็อกเหรียญ',
  'register_gift.充值金流水*当前解锁金币速率': 'การเดิมพันฝากเงิน × อัตราการปล่อย',
  'register_gift.解锁速率': 'อัตราการปล่อย',
  'register_gift.解锁优先级': 'ปลดล็อกลำดับความสำคัญ',
  'register_gift.查看规则': 'ดูกฎเกณฑ์',
  'register_gift.充值金流水越高,解锁金币越多': 'การเดิมพันฝากเงินที่สูงขึ้น จะทำให้คุณปลดล็อกเหรียญได้มากขึ้น!',

  'register_gift.header.锁定金额': 'ล็อค',
  'register_gift.header.可解锁': 'เคลม',
  'register_gift.header.领取': 'รับ',

}