export default {
  'home.minbanner.activity1.标题': 'Penawaran Baru',
  'home.minbanner.activity1.描述': 'Hingga',
  'home.minbanner.activity1.全部': 'Diberikan',
  'home.minbanner.activity3.标题': 'Check-in Harian',
  'home.minbanner.activity3.描述': 'Apa yang Anda tarik, Anda dapatkan',
  'home.minbanner.activity4.标题': 'Jadilah VIP dan Menangkan Hadiah Besar',

  'home.game.lobby': 'Semua',
  'home.game.hot': 'Populer',
  'home.game.slots': 'Slots',
  'home.game.BY': 'Memancing',
  'home.game.casino': 'Permainan Poker',
  'home.game.SX': 'Kasino hidup',
  'home.game.TY': 'Olahraga',
  'home.game.NEW': 'Baru',
  'home.game.AG': 'AG',
  'home.game.BBIN': 'BBIN',
  'home.game.CMD': 'CMD',
  'home.game.CQ9': 'CQ9',
  'home.game.EVO': 'EVO',
  'home.game.FBGAME': 'FBGAME',
  'home.game.JDB': 'JDB',
  'home.game.JILI': 'JILI',
  'home.game.LHDJ': 'LHDJ',
  'home.game.NEXTSPIN': 'NEXTSPIN',
  'home.game.PG': 'PG',
  'home.game.PLAYNGO': 'PLAYNGO',
  'home.game.PLAYTECH': 'PLAYTECH',
  'home.game.PP': 'PP',
  'home.game.TADA': 'TADA',
  'home.game.SW': 'SW',
  'home.game.ZITRO': 'ZITRO',
  'home.game.BG': 'BG',
  'home.game.REDTIGER': 'REDTIGER',
  'home.game.RELAXGAMING': 'RELAX-GAMING',
  'home.game.SA': 'SA',
  'home.game.SABA': 'SABA',
  'home.game.SPRIBECASINO': 'SPRIBE-CASINO',
  'home.game.SUPERSPADE': 'SUPERSPADE',
  'home.game.V8': 'V8',
  'home.game.WM': 'WM',
  'home.game.WX168': 'WX168',
  'home.game.查看全部': 'Lihat Semua',

  'home.exitdownpopup.提示': 'Pemberitahuan',
  'home.exitdownpopup.内容': 'Pemain yang terhormat, karena masalah jaringan, {coin} koin Anda di platform {platform} belum diselesaikan. Harap tunggu beberapa waktu.',
  'home.exitdownpopup.联系客服': 'Hubungi Layanan Pelanggan',

  'home.share.加入我们的社区': 'Bergabunglah dengan komunitas kami',

  'home.icons.支持的充值平台': 'Platform Isi Ulang yang Didukung',
  'home.icons.赞助和博彩责任': 'Mitra',

  'home.metadesc.赞助和博彩责任': '7WIN.GAME',
  'home.metadesc.描述': 'Dengan pendekatan yang berfokus pada pemain, 7win.game mampu memuaskan jutaan penjudi di seluruh dunia. 7win.game menempatkan prioritas pada komunitasnya, memastikan pengalaman perjudian yang abadi dan menghibur. Penggunaan dan akses Anda ke 7win.game menandakan bahwa Anda sepenuhnya memahami dan menyetujui untuk terikat secara hukum oleh isi dari Ketentuan Layanan dan Kebijakan Permainan Bertanggung Jawab kami.',

  'home.selectsession.门槛': 'Ambang',
  'home.selectsession.初级场': 'Pemula',
  'home.selectsession.中级场': 'Menengah',
  'home.selectsession.高级场': 'Lanjutan',
  'home.selectsession.精英场': 'Elite',
  'home.selectsession.余额不足': 'Saldo Tidak Cukup',
  'home.selectsession.只有充值金才能玩': 'Hanya dapat bermain dengan saldo isi ulang',
  'home.selectsession.充值金币余额不满足当前场次进入门槛': 'Saldo isi ulang Anda tidak memenuhi ambang masuk sesi ini',
  'home.selectsession.当前场次条件是xx': 'Syarat sesi saat ini adalah {min}-{max}, Anda tidak memenuhi syarat!',
  'home.selectsession.当前余额不足': 'Saldo Anda tidak cukup, silakan isi ulang',

  'home.entergame.用户余额不足': 'Saldo pengguna tidak mencukupi',
  'home.entergame.上分失败': 'Gagal menambahkan skor',
  'home.entergame.三方游戏进入提示': 'Permainan saat ini hanya mendukung taruhan dengan aset yang telah diisi ulang! Kurs pada Platform saat ini ({platform_name}) adalah {rate}:1! Saldo deposit Anda saat ini adalah {balance}! Setelah memasuki permainan, aset Anda yang tersedia akan menjadi {coin}!',
  'home.entergame.三方游戏不能进入提示': 'Permainan saat ini hanya mendukung taruhan dengan aset yang telah diisi ulang! Koin yang telah Anda isi ulang tidak mencukupi! Anda tidak dapat memasuki permainan saat ini!',

  'home.loading.首次加载慢请耐心等待': 'Pemuatan pertama game mungkin memerlukan waktu yang lama. Harap bersabar!',
  'home.guide.奖金被锁定下注可解锁': 'Bonus terkunci! Pasang taruhan untuk membuka kunci! Penarikan gratis setelah dibuka!',
  'home.guide.下注R$1可解锁R$5': 'Pasang taruhan R$1 untuk membuka R$5! Semakin banyak Anda bertaruh, semakin banyak yang Anda buka!',
  'home.guide.点击按钮可立即领取奖励': 'Klik tombol untuk mengklaim hadiah Anda secara instan! Penarikan gratis setelah diklaim! ',
}
