export default {
  'wallt.充值': '充值',
  'wallt.提现': '提现',

  'wallt.recharge.充值方式': '充值方式',
  'wallt.recharge.充值金额': '充值金额',
  'wallt.recharge.确认充值': '确认充值',
  'wallt.recharge.请输入银行账户': '请输入银行账户',
  'wallt.recharge.请输入开户名': '请输入开户名',
  'wallt.recharge.请输入手机号': '请输入手机号',
  'wallt.recharge.输入您的实际付款Momo帐户以完成付款': '输入您的实际付款Momo帐户以完成付款',
  'wallt.recharge.请准确填写您的支付账户,填写错误可能会导致转账资金的损失': '请准确填写您的支付账户,填写错误可能会导致转账资金的损失',
  'wallt.recharge.填写正确的银行相关信息可提高充值成功率': '填写正确的银行相关信息可提高充值成功率',
  'wallt.recharge.汇率': '汇率',
  'wallt.recharge.参与活动': '参与活动',
  'wallt.recharge.注册送': '注册送',
  'wallt.recharge.签到': '签到',
  'wallt.recharge.当前参与活动': '当前参与活动',
  'wallt.recharge.充值额度': '充值额度',
  'wallt.recharge.赠送额度': '赠送额度',
  'wallt.recharge.您还不能参加此活动': '您还不能参加此活动',
  'wallt.recharge.下订单失败': '下订单失败',
  'wallt.recharge.下订单失败，请尝试大额支付或其他支付通道!': '下订单失败，请尝试大额支付或其他支付通道!',
  'wallt.recharge.当前充值渠道正在维护': '当前充值渠道正在维护',
  'wallt.recharge.当前银行正在维护': '当前银行正在维护',
  'wallt.recharge.您当前不能参与活动确认要充值吗': '您当前不能参与活动确认要充值吗?',
  'wallt.recharge.选择银行卡': '选择银行卡',
  'wallt.recharge.充值': 'Chọn số tiền',
  'wallt.recharge.手续费': 'Phí xử lý',
  'wallt.recharge.需要扣除手续费': 'phương thức nạp tiền này cần phí xử lý',
  'wallt.recharge.实际到账': 'Số tiền thực nhận',
  'wallt.recharge.你不能参与任何活动,还要遭扣除手续费': 'Phương thức nạp tiền này không hỗ trợ tham gia bất kỳ hoạt động nào! Nếu mệnh giá thẻ cào cao hơn lệnh nạp bạn đã tạo, phần chênh lệch người chơi tự chịu tốn thất!',
  'wallt.recharge.确认': '确认',
  'wallt.recharge.二维码描述': 'Nếu không thể sử dụng mã QR, vui lòng sao chép thông tin bên dưới để chuyển tiền',
  'wallt.recharge.银行名称': 'Tên ngân hàng',
  'wallt.recharge.账户': 'Số tài khoản',
  'wallt.recharge.账户名称': 'Tên tài khoản',
  'wallt.recharge.转账金额': 'Số tiền chuyển',
  'wallt.recharge.复制': 'Sao chép',
  'wallt.recharge.注意': 'Lưu ý',
  'wallt.recharge.请充值相应金额的USDT!不正确的充值金额将导致游戏币无法充值!': '请充值相应金额的USDT!不正确的充值金额将导致游戏币无法充值!',
  'wallt.recharge.您有一笔订单未支付': '您有一笔{amount}USTD的订单未支付,请先付款!',
  'wallt.recharge.规则1': '1.Vui lòng nhập chính xác nội dung và số tiền yêu từ hệ thống. Thông tin không chính xác sẽ không thể tự động lên điểm.',
  'wallt.recharge.规则2': '2. Nếu không thể sử dụng mã QR Code, vui lòng sao chép tàikhoản thanh toán.',
  'wallt.recharge.规则3': '3.Khi chuyển khoản không làm mới trình duyệt.',
  'wallt.recharge.认证': 'PCI DSS certification',
  'wallt.recharge.认证描述': 'Đạt chứng nhận bảo mật dữ liệu tài chính có thẳm quyền và tuân thủ các tiêu chuẩn bảo mật quốc tế',
  'wallt.rechargechannel.网关': '网关',
  'wallt.rechargechannel.银行转账': '银行转账',
  'wallt.rechargechannel.ZaloPay': 'ZaloPay',
  'wallt.rechargechannel.VIETQR': 'VIETQR',
  'wallt.rechargechannel.MomoPay': 'MomoPay',
  'wallt.rechargechannel.Nạp thẻ cào': 'Nạp thẻ cào',
  'wallt.rechargechannel.Chỉ định QR BANK': 'Chị định QR BANK',
  'wallt.rechargechannel.VTPay': 'VTPay',
  'wallt.rechargechannel.银行扫码': '银行扫码',

  'wallt.withdraw.绑定银行卡': '绑定银行卡',
  'wallt.withdraw.更换银行卡': '更换银行卡',
  'wallt.withdraw.提现信息': '提现信息',
  'wallt.withdraw.最高可提现金额': '最高可提现金额',
  'wallt.withdraw.当前选择金额': '当前选择金额',
  'wallt.withdraw.服务费': '服务费',
  'wallt.withdraw.收到金额': '收到金额',
  'wallt.withdraw.手续费': '手续费',
  'wallt.withdraw.当前提现金额大于最大可提现金额': '当前提现金额大于最大可提现金额',
  'wallt.withdraw.提现金额': '提现金额',
  'wallt.withdraw.当前提现渠道正在维护': '当前提现渠道正在维护',
  'wallt.withdraw.确定': '确定',
  'wallt.withdraw.当前金额正在维护': '当前金额正在维护',
  'wallt.withdraw.当前提现金额不符合最大可提现金额': '当前提现金额不符合最大可提现金额',
  'wallt.withdraw.不满足VIP提款金额要求请升级VIP': '不满足VIP提款金额要求！请升级VIP！',
  'wallt.withdraw.不满足vip1提现金额要求请充值': '不满足vip提现金额要求，充值150成为vip2，可提现全部金额',
  'wallt.withdraw.您的余额已满足提现要求可以立即提现': '您的余额已达到提现门槛！成为VIP即可立即提现！',
  'wallt.withdraw.您的余额已满足提现要求可以立即提现3': '充值R$100即可成为VIP2！VIP2可立即全额提现！',
  'wallt.withdraw.您的余额已满足提现要求可以立即提现4': '您可以立即提取R$10！请立即提取！',
  'wallt.withdraw.您的余额已满足提现要求可以立即提现5': '再充值R$50可提现全部金额',
  'wallt.withdraw.成为VIP后可以立即提现': '成为VIP后，可以立即提现',
  'wallt.withdraw.请选择银行': '请选择银行',
  'wallt.withdraw.请输入您的姓名': '请输入您的姓名',
  'wallt.withdraw.请输入您的银行卡号': '请输入您的银行卡号',
  'wallt.withdraw.请输入您的钱包地址': '请输入您的钱包地址',
  'wallt.withdraw.请输入您的电子邮件': '请输入您的电子邮件',
  'wallt.withdraw.请输入提款密码': '请输入提款密码',
  'wallt.withdraw.请确认提款密码': '请确认提款密码',
  'wallt.withdraw.请检查表单是否填写完整': '请检查表单是否填写完整',
  'wallt.withdraw.请输入正确的邮箱': '请输入正确的邮箱',
  'wallt.withdraw.密码不能少于6位': '密码不能少于6位',
  'wallt.withdraw.两次密码输入不一致': '两次密码输入不一致',
  'wallt.withdraw.提款密码': '提款密码',
  'wallt.withdraw.忘记密码': '忘记密码',
  'wallt.withdraw.请输入密码': '请输入密码',
  'wallt.withdraw.更换银行卡需要验证交易密码': '更换银行卡需要验证交易密码',
  'wallt.withdraw.密码错误': '密码错误',
  'wallt.withdraw.服务器内部错误': '服务器内部错误',
  'wallt.withdraw.短信验证': '短信验证',
  'wallt.withdraw.手机号': '手机号',
  'wallt.withdraw.请输入验证码': '请输入验证码',
  'wallt.withdraw.发送': '发送',
  'wallt.withdraw.验证码发送成功': '验证码发送成功',
  'wallt.withdraw.验证码错误': '验证码错误',
  'wallt.withdraw.发送验证码': '发送验证码',
  'wallt.withdraw.br.绑定提款账号': '绑定提款账号',
  'wallt.withdraw.br.请选择取款方式': '请选择取款方式',
  'wallt.withdraw.br.请输入用户姓名': '请输入用户姓名',
  'wallt.withdraw.br.请输入取款账号': '请输入取款账号',
  'wallt.withdraw.br.请输入邮箱': '请输入邮箱',
  'wallt.withdraw.br.请输入手机号': '请输入手机号',
  'wallt.withdraw.br.请输入正确的CPF': '请输入正确的CPF',

  'wallt.withdraw.可提现佣金': '可提现佣金',
  'wallt.withdraw.代理佣金提现': '代理佣金提现',
  'wallt.withdraw.当前界面不可提现代理佣金部分': '当前界面不可提现代理佣金！代理佣金提现请前往代理页面！',
  'wallt.withdraw.每个人都可以参与的独立提款系统': '每个人都可以参与的独立提款系统',

  'wallt.transrecord.交易记录': '交易记录',
  'wallt.transrecord.充值': '充值',
  'wallt.transrecord.提现': '提现',
  'wallt.transrecord.没有数据': '没有数据',
  'wallt.transrecord.没有更多数据了': '没有更多数据了',
  'wallt.transrecord.支付中': '支付中',
  'wallt.transrecord.成功': '成功',
  'wallt.transrecord.失败': '失败',
  'wallt.transrecord.处理中': '处理中',
  'wallt.transrecord.类型': '类型',
  'wallt.transrecord.金额': '金额',
  'wallt.transrecord.渠道': '渠道',
  'wallt.transrecord.状态': '状态',


}