export default {
  'register_gift.popup.标题': 'GRANDE BÔNUS PARA CADA DEPÓSITO',
  'register_gift.popup.描述': 'ATÉ',
  'register_gift.popup.详情': 'Detalhes',
  'register_gift.popup.赠送金币解锁后无需流水即可提现': 'Saque das Moedas Liberadas Sem Requisitos de Apostas',
  'register_gift.popup.赠送': 'Bônus',
  'register_gift.popup.立即充值': 'Depositar Agora',
  'register_gift.popup.首次充值': '1º Depósito',
  'register_gift.popup.二次充值': '2º Depósito',
  'register_gift.popup.三次充值': '3º Depósito',
  'register_gift.popup.四次充值': '4º Depósito',
  'register_gift.popup.活动刷新倒计时': 'Atualizar contagem decrescente',

  'register_gift.标题': 'GRANDE BÔNUS PARA CADA DEPÓSITO',
  'register_gift.首次充值': '1º Depósito',
  'register_gift.送全部注册金币': 'Bônus: {rate} Saldo do Bônus de Novato',
  'register_gift.赠送金额': 'Bônus',
  'register_gift.玩法': 'Como Jogar',
  'register_gift.玩法规则1': '1. Deposite qualquer valor para receber {rate}% do saldo do Bônus de Novato',
  'register_gift.玩法规则1.1': '1. Receba {rate}% do valor do depósito após um depósito bem-sucedido',
  'register_gift.玩法规则2': '2. Após o depósito, o bônus não poderá ser usado até ser liberado com as Apostas de Depósito',
  'register_gift.玩法规则3': '3. Como Liberar: Apostas de Depósito',
  'register_gift.玩法规则4': '4. Saque das Moedas Liberadas Sem Requisitos de Apostas',
  'register_gift.举例': 'Exemplo',
  'register_gift.赠送金币为': 'Bônus',
  'register_gift.当前充值金流水': 'Apostas de Depósito',
  'register_gift.领取解锁金币后将重新计算': 'Recalcular Após Liberação',
  'register_gift.可解锁金币': 'Reivindicar',
  'register_gift.领取后赠送金币余额': 'Saldo de Bônus Após Liberação',
  'register_gift.立即充值': 'Depositar Agora',
  'register_gift.最低充值要求': 'Requisitos mínimos de recarga',
  'register_gift.任意金额': 'Qualquer quantia',

  'register_gift.锁定金币总余额': 'Moedas Presas:',
  'register_gift.充值金流水': 'Apostas Depósito',
  'register_gift.锁定金币': 'Bloqueado',
  'register_gift.速率': 'Taxa',
  'register_gift.已领取金币': 'Reivindicado',
  'register_gift.可领取金币': 'Para Retirar',
  'register_gift.领取金币': 'Reivindicar',
  'register_gift.首充赠送金币': 'Bônus 1º Depósito',
  'register_gift.二充赠送金币': 'Bônus 2º Depósito',
  'register_gift.三充赠送金币': 'Bônus 3º Depósito',
  'register_gift.四充赠送金币': 'Bônus 4º Depósito',

  'register_gift.第x次充值': 'O {num}º Depósito',
  'register_gift.送充值金额': 'Bônus: Valor Depósito',
  'register_gift.充值金额': 'Valor Depósito',

  'register_gift.恭喜你已获得所有新手奖励': 'Você recebeu todas as recompensas',
  'register_gift.如何玩': 'Como Jogar',
  'register_gift.充值金币流水可解锁赠送金币': 'O bônus não poderá ser usado até ser liberado com as Apostas de Depósito',
  'register_gift.解锁金币计算公式': 'Como Liberar as Moedas',
  'register_gift.充值金流水*当前解锁金币速率': 'Apostas de Depósito × Taxa de Liberação',
  'register_gift.解锁速率': 'Taxa de Liberação',
  'register_gift.解锁优先级': 'Prioridade de Liberação',
  'register_gift.查看规则': 'Ver Regras',
  'register_gift.充值金流水越高,解锁金币越多': 'Quanto maior a Aposta de Depósito, mais Moedas você libera!',

  'register_gift.header.锁定金额': 'Bloqueado',
  'register_gift.header.可解锁': 'Reivindicar',
  'register_gift.header.领取': 'Reivindicar',
}