import { getConfig } from '@/utils/function';

const all_url_config:any = {
    VN:{
        "AgentUrl":{"key":"AgentUrl","url":"https://idgb-vn-h.winnercluba.com"},
        "CenterApiUrl":{"key":"CenterApiUrl","url":"https://api2.facegoapp.com"},
        "CocosBundleUrl":{"key":"CocosBundleUrl","url":"http://cocosgame.facegaclub.com"},
        "EchoUrl":{"key":"EchoUrl","url":"https://idgb.notice.facegaclub.com/pay/echo"},
        "GameIconUrl":{"key":"GameIconUrl","url":"https://yuen.gamesort.facegaclub.com"},
        "GameListUrl":{"key":"GameListUrl","url":"https://yuen.notice.facegaclub.com/world/gamelist"},
        "ImageUpUrl":{"key":"ImageUpUrl","url":"https://store-api.ads1ddcc.com/store/v1/sign-upload-object"},
        "MiniGameWsUrl":{"key":"MiniGameWsUrl","url":"http://xipicrash.facegoapp.com:10810/LaunchInfo"},
        "ServiceUrl":{"key":"ServiceUrl","url":"https://direct.lc.chat/12458301/4"},
        "WorldCupGameUrl":{"key":"WorldCupGameUrl","url":"https://worldcup.ads6ddcc.com"},
        "WorldCupHotUrl":{"key":"WorldCupHotUrl","url":"https://api.ads6ddcc.com/world_cup/v1/today_hot"},
        "WorldCupRankUrl":{"key":"WorldCupRankUrl","url":"https://api.ads6ddcc.com/world_cup/v1/leaderboard_list"},
        "AcitityUrl":{"key":"AcitityUrl","url":"https://yuen.fcwebh5.facegaclub.com/"},
        "XiPiGameUrl":{"key":"XiPiGameUrl","url":"https://idgb.xipigames.facegaclub.com/game"},
        "TokenUrl":{"key":"TokenUrl","url":"https://statistics.xjp.facegoapp.com"}
    },
    TH:{
        "AgentUrl":{"key":"AgentUrl","url":"https://idgb-vn-h.winnercluba.com"},
        "CenterApiUrl":{"key":"CenterApiUrl","url":"https://api2.facegoapp.com"},
        "CocosBundleUrl":{"key":"CocosBundleUrl","url":"http://cocosgame.facegaclub.com"},
        "EchoUrl":{"key":"EchoUrl","url":"https://idgb.notice.facegaclub.com/pay/echo"},
        "GameIconUrl":{"key":"GameIconUrl","url":"https://yuen.gamesort.facegaclub.com"},
        "GameListUrl":{"key":"GameListUrl","url":"https://yuen.notice.facegaclub.com/world/gamelist"},
        "ImageUpUrl":{"key":"ImageUpUrl","url":"https://store-api.ads1ddcc.com/store/v1/sign-upload-object"},
        "MiniGameWsUrl":{"key":"MiniGameWsUrl","url":"http://xipicrash.facegoapp.com:10810/LaunchInfo"},
        "ServiceUrl":{"key":"ServiceUrl","url":"https://direct.lc.chat/12458301/4"},
        "WorldCupGameUrl":{"key":"WorldCupGameUrl","url":"https://worldcup.ads6ddcc.com"},
        "WorldCupHotUrl":{"key":"WorldCupHotUrl","url":"https://api.ads6ddcc.com/world_cup/v1/today_hot"},
        "WorldCupRankUrl":{"key":"WorldCupRankUrl","url":"https://api.ads6ddcc.com/world_cup/v1/leaderboard_list"},
        "AcitityUrl":{"key":"AcitityUrl","url":"https://yuen.fcwebh5.facegaclub.com/"},
        "XiPiGameUrl":{"key":"XiPiGameUrl","url":"https://idgb.xipigames.facegaclub.com/game"},
        "TokenUrl":{"key":"TokenUrl","url":"https://statistics.xjp.facegoapp.com"}
    },
    TZ:{
        "AgentUrl":{"key":"AgentUrl","url":"https://idgb-vn-h.winnercluba.com"},
        "CenterApiUrl":{"key":"CenterApiUrl","url":"https://api2ga.facegoapp.com"},
        "CocosBundleUrl":{"key":"CocosBundleUrl","url":"http://cocosgame.facegaclub.com"},
        "EchoUrl":{"key":"EchoUrl","url":"https://idgb.notice.facegaclub.com/pay/echo"},
        "GameIconUrl":{"key":"GameIconUrl","url":"https://yuen.gamesort.facegaclub.com"},
        "GameListUrl":{"key":"GameListUrl","url":"https://yuen.notice.facegaclub.com/world/gamelist"},
        "ImageUpUrl":{"key":"ImageUpUrl","url":"https://store-api.ads1ddcc.com/store/v1/sign-upload-object"},
        "MiniGameWsUrl":{"key":"MiniGameWsUrl","url":"http://xipicrash.facegoapp.com:10810/LaunchInfo"},
        "ServiceUrl":{"key":"ServiceUrl","url":"https://direct.lc.chat/12458301/4"},
        "WorldCupGameUrl":{"key":"WorldCupGameUrl","url":"https://worldcup.ads6ddcc.com"},
        "WorldCupHotUrl":{"key":"WorldCupHotUrl","url":"https://api.ads6ddcc.com/world_cup/v1/today_hot"},
        "WorldCupRankUrl":{"key":"WorldCupRankUrl","url":"https://api.ads6ddcc.com/world_cup/v1/leaderboard_list"},
        "AcitityUrl":{"key":"AcitityUrl","url":"https://yuen.fcwebh5.facegaclub.com/"},
        "XiPiGameUrl":{"key":"XiPiGameUrl","url":"https://idgb.xipigames.facegaclub.com/game"},
        "TokenUrl":{"key":"TokenUrl","url":"https://statistics.xjp.facegoapp.com"}
    },
    PH:{
        "AgentUrl":{"key":"AgentUrl","url":"https://idgb-vn-h.winnercluba.com"},
        "CenterApiUrl":{"key":"CenterApiUrl","url":"https://api2.facegoapp.com"},
        "CocosBundleUrl":{"key":"CocosBundleUrl","url":"http://cocosgame.facegaclub.com"},
        "EchoUrl":{"key":"EchoUrl","url":"https://idgb.notice.facegaclub.com/pay/echo"},
        "GameIconUrl":{"key":"GameIconUrl","url":"https://yuen.gamesort.facegaclub.com"},
        "GameListUrl":{"key":"GameListUrl","url":"https://yuen.notice.facegaclub.com/world/gamelist"},
        "ImageUpUrl":{"key":"ImageUpUrl","url":"https://store-api.ads1ddcc.com/store/v1/sign-upload-object"},
        "MiniGameWsUrl":{"key":"MiniGameWsUrl","url":"http://xipicrash.facegoapp.com:10810/LaunchInfo"},
        "ServiceUrl":{"key":"ServiceUrl","url":"https://direct.lc.chat/12458301/4"},
        "WorldCupGameUrl":{"key":"WorldCupGameUrl","url":"https://worldcup.ads6ddcc.com"},
        "WorldCupHotUrl":{"key":"WorldCupHotUrl","url":"https://api.ads6ddcc.com/world_cup/v1/today_hot"},
        "WorldCupRankUrl":{"key":"WorldCupRankUrl","url":"https://api.ads6ddcc.com/world_cup/v1/leaderboard_list"},
        "AcitityUrl":{"key":"AcitityUrl","url":"https://yuen.fcwebh5.facegaclub.com/"},
        "XiPiGameUrl":{"key":"XiPiGameUrl","url":"https://idgb.xipigames.facegaclub.com/game"},
        "TokenUrl":{"key":"TokenUrl","url":"https://statistics.xjp.facegoapp.com"}
    },
    KE:{
        "AgentUrl":{"key":"AgentUrl","url":"https://idgb-vn-h.winnercluba.com"},
        "CenterApiUrl":{"key":"CenterApiUrl","url":"https://api2ga.facegoapp.com"},
        "CocosBundleUrl":{"key":"CocosBundleUrl","url":"http://cocosgame.facegaclub.com"},
        "EchoUrl":{"key":"EchoUrl","url":"https://idgb.notice.facegaclub.com/pay/echo"},
        "GameIconUrl":{"key":"GameIconUrl","url":"https://yuen.gamesort.facegaclub.com"},
        "GameListUrl":{"key":"GameListUrl","url":"https://yuen.notice.facegaclub.com/world/gamelist"},
        "ImageUpUrl":{"key":"ImageUpUrl","url":"https://store-api.ads1ddcc.com/store/v1/sign-upload-object"},
        "MiniGameWsUrl":{"key":"MiniGameWsUrl","url":"http://xipicrash.facegoapp.com:10810/LaunchInfo"},
        "ServiceUrl":{"key":"ServiceUrl","url":"https://direct.lc.chat/12458301/4"},
        "WorldCupGameUrl":{"key":"WorldCupGameUrl","url":"https://worldcup.ads6ddcc.com"},
        "WorldCupHotUrl":{"key":"WorldCupHotUrl","url":"https://api.ads6ddcc.com/world_cup/v1/today_hot"},
        "WorldCupRankUrl":{"key":"WorldCupRankUrl","url":"https://api.ads6ddcc.com/world_cup/v1/leaderboard_list"},
        "AcitityUrl":{"key":"AcitityUrl","url":"https://yuen.fcwebh5.facegaclub.com/"},
        "XiPiGameUrl":{"key":"XiPiGameUrl","url":"https://idgb.xipigames.facegaclub.com/game"},
        "TokenUrl":{"key":"TokenUrl","url":"https://statistics.xjp.facegoapp.com"}
    },
    BR:{
        "AgentUrl":{"key":"AgentUrl","url":"https://idgb-vn-h.winnercluba.com"},
        "CenterApiUrl":{"key":"CenterApiUrl","url":"https://api2ga.facegoapp.com"},
        "CocosBundleUrl":{"key":"CocosBundleUrl","url":"http://cocosgame.facegaclub.com"},
        "EchoUrl":{"key":"EchoUrl","url":"https://idgb.notice.facegaclub.com/pay/echo"},
        "GameIconUrl":{"key":"GameIconUrl","url":"https://yuen.gamesort.facegaclub.com"},
        "GameListUrl":{"key":"GameListUrl","url":"https://yuen.notice.facegaclub.com/world/gamelist"},
        "ImageUpUrl":{"key":"ImageUpUrl","url":"https://store-api.ads1ddcc.com/store/v1/sign-upload-object"},
        "MiniGameWsUrl":{"key":"MiniGameWsUrl","url":"http://xipicrash.facegoapp.com:10810/LaunchInfo"},
        "ServiceUrl":{"key":"ServiceUrl","url":"https://direct.lc.chat/12458301/4"},
        "WorldCupGameUrl":{"key":"WorldCupGameUrl","url":"https://worldcup.ads6ddcc.com"},
        "WorldCupHotUrl":{"key":"WorldCupHotUrl","url":"https://api.ads6ddcc.com/world_cup/v1/today_hot"},
        "WorldCupRankUrl":{"key":"WorldCupRankUrl","url":"https://api.ads6ddcc.com/world_cup/v1/leaderboard_list"},
        "AcitityUrl":{"key":"AcitityUrl","url":"https://yuen.fcwebh5.facegaclub.com/"},
        "XiPiGameUrl":{"key":"XiPiGameUrl","url":"https://idgb.xipigames.facegaclub.com/game"},
        "TokenUrl":{"key":"TokenUrl","url":"https://statistics.xjp.facegoapp.com"}
    },
};

const url_config = all_url_config[getConfig()?.country];

export default url_config;