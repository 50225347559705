const temp = {
    namespaced: true,
    state() {
        return {
            withdraw: false
        }
    },
    mutations: {
        setWithdrawTemp(state: any, withdraw_temp: any) {
            state.withdraw = withdraw_temp;
        }
    },
}

export default temp;